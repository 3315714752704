import React, { useState, useEffect, useContext, useMemo } from 'react';
import { GET } from 'utils/useApi';
import Layout from 'antd/lib/layout/layout';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { LayoutProp } from 'types/Content';
import { NotificationProvider } from 'context/NotificationContext';
import { getAuthHeader } from 'api/request';
import { useLocation } from 'react-router';
import { getChatList } from 'api/chat';
import HeaderNav from 'components/HeaderNav';
import Footer from 'components/Footer';
import SignInUpModal from 'components/SignInUpModal';
import AuthContext from 'context/AuthContext';
import EditIntentModal from './ExitIntentModal';
import { isCN, isUAE } from 'utils/env';
import { useTranslation } from 'react-i18next';
import { KEY_CONTENT_ROUTES } from 'routing';
const { Content } = Layout;

const NO_FOOTER_PREFIXES = [
  '/post-job',
  '/customer/',
  '/customer-dashboard',
  '/dashboard',
  '/signup',
  '/post',
  '/browse',
  '/messages',
  '/bookings',
  '/a-level-retakes',
  '/profile',
  '/customer-profile',
  '/customer-feedback',
  '/tutor-reference',
  '/booking',
  '/jobs',
  '/pricing'
];

const VERIFY_MAIL_BANNER_PREFIXES = [
  '/messages',
  '/customer-dashboard',
  '/bookings',
  '/customer/jobs',
];

const DASHBOARD_FOOTER_PREFIXES = [
  '/customer-dashboard',
  '/dashboard',
  '/messages',
  '/bookings',
  '/profile',
  '/customer-profile',
  '/customer-feedback',
  '/tutor-reference',
  '/booking',
  '/post',
  '/jobs'
];

const FooterUkUnToken_PREFIXES=[
  '/'
  // '/search-results',
]
const showUkNewFooter_PREFIXES=[
  '/',
  '/pricing',
]
const showHomepageHeader_PREFIXES=[
  '/',
  '/pricing',
]

function AppLayout(props: LayoutProp) {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const authToken = getAuthHeader()
    .Authorization.replace('Token', '')
    .trim();
  const auth = useContext(AuthContext);
  const { REACT_APP_WS_ENDPOINT } = process.env;

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [webSocket, setWebSocket] = useState<WebSocket>();
  const [hasLogin,setHasLogin]=useState<boolean>(false)
  const [showExitIntent, setShowExitIntent] = useState(false)

  const showFooter = !NO_FOOTER_PREFIXES.find(prefix =>
    props.location.pathname.startsWith(prefix)||props.location.pathname==='/'
  );


  const showUkUnloginMobileFooter=!!FooterUkUnToken_PREFIXES.find(prefix =>
      props.location.pathname===prefix
  )
  const showUkNewFooter=!!showUkNewFooter_PREFIXES.find(prefix =>
    props.location.pathname===prefix
  )

  const showHomepageHeader=!!showHomepageHeader_PREFIXES.find(prefix =>
    props.location.pathname===prefix
  )


  const showDashboardFooter = !!DASHBOARD_FOOTER_PREFIXES.find(prefix =>
    props.location.pathname.startsWith(prefix)
  );

  const showBanner = !!VERIFY_MAIL_BANNER_PREFIXES.find(prefix =>
    props.location.pathname.startsWith(prefix)
  );

  const getUnreadNotifications = async () => {
    const useToken = true;
    try {
      const result = (await GET(
        'v2/chat/unread-count/',
        useToken
      )) as any;
      setUnreadMessagesCount(result.data.count);
    } catch (err) {
      console.error(err);
    }
  };

  const getNewSocket = () => {
    try {
      const ws = new WebSocket(`${REACT_APP_WS_ENDPOINT}/?token=${authToken}`);
      setWebSocket(ws);
    }catch (err){
      console.log(err)
    }
  };

  const notificationCtx = {
    unreadMessagesCount,
    unreadNotificationsCount,
    setUnreadMessagesCount,
    setUnreadNotificationsCount,
    getUnreadNotifications,
    getNewSocket,
    webSocket
  };

  useEffect(() => {
    if (authToken && authToken !== 'null') {
      getNewSocket();
      getUnreadNotifications()
    }
  }, [REACT_APP_WS_ENDPOINT, authToken]);

  useEffect(() => {
    window.scrollTo(0,0)
  }, [props.location.pathname]);

  // useEffect(() => {
  //   const exitEvent = (evt: MouseEvent) => {
  //     if (!(evt as any).toElement && !evt.relatedTarget && evt.clientY < 0) {
  //       document.removeEventListener('mouseout', exitEvent)
  //       setShowExitIntent(true)
  //     }
  //   }
  //   document.addEventListener('mouseout', exitEvent)
  // }, [])
  useEffect(()=>{
    if(auth.user){
      setHasLogin(true)
    }else{
      setHasLogin(false)
    }
  }, [auth.user])
  const style64={
    minHeight:"calc(100vh - 64px)",
    marginTop:'64px',
    background:"#FFF"
  }
  const style80={
    minHeight:"calc(100vh - 80px)",
    marginTop:'80px',
    background: "#FFF"
  }
  const style110={
    minHeight:"calc(100vh - 110px)",
    marginTop:'110px',
    background: "#FFF"
  }

  const updateTitle = useMemo(
    () => { 
      // var defaultTitle = t('Online Tutoring | Find Your Perfect Tutor | Tutor House')
      var defaultTitle = "Online Tutoring | Find Your Perfect Tutor | " + (isUAE ? (isCN ? "MandarinGURU" : "Nadwa Tutoring") : "Tutor House")
      var title = defaultTitle
      const _params: string[] = props.location.pathname.split('/')
      const params = _params.map((e: string) => {
        const pi = parseInt(e)
        const isInt = !isNaN(pi) && Number.isInteger(pi)
        return isInt ? " " : e
      })
      if (props.location.pathname == KEY_CONTENT_ROUTES.LOADING) {
      } else if (params.length > 0) {
        title = params.map((el: string) => {
          const first = el.charAt(0).toUpperCase()
          return first + el.slice(1)
        }).join(' ').trim()
        const sufTitle = " | " + (isUAE ? (isCN ? "Mandaringuru" : "Nadwa") : "Tutor House")
        title = title ? title + sufTitle : defaultTitle
      }
      return <Helmet><title>{title}</title></Helmet>
  }, [props.location.pathname]);

  return (
    <NotificationProvider value={notificationCtx}>
      <Layout
        className="layout"
        style={(!isUAE&&showHomepageHeader&&!hasLogin)?style80:((isUAE&&!hasLogin)?style110:style64)}
      > 
        {updateTitle}
        <HeaderNav showBanner={showBanner} />
        <Content style={isUAE?{backgroundColor:'#f8f4fb'}:{}}>{props.children}</Content>
        <Footer />
        {!auth.user && <SignInUpModal
          onSuccess={(u) => {}}
          visible={auth.isSignupModalVisible}
          onCancel={() => auth.setSignupModalVisibility(false)}
        />}
        {!isUAE&&!auth.user&&<EditIntentModal />}
      </Layout>
    </NotificationProvider>
  );
}

export default withRouter((props: LayoutProp) => (
  <AppLayout {...props}>{props.children}</AppLayout>
));
