import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import I18n from '../src/utils/i18n'
import axios from 'axios';

I18n.setDefLan()

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // fallbackLng: I18n.isAr ? "ar" : "en",
    fallbackLng: 'en',
    // lng: 'ar',
    // debug: true,
    // saveMissing: true,

    // have a common namespace used around the full app
    // ns: ["translation"],
    // defaultNS: "translation",

    backend: {
      loadPath: "/assets/i18n/{{lng}}/translation.json"
    },

    // keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    },

    react: {
      useSuspense: false,
      // wait: true
    },

    // detection: {
    //   order: ["cookie", "localStorage"],
    //   caches: ["cookie"]
    // }

    // preload: true
  })
  // .then((t) => {
  //   document.title = t('Online Tutoring | Find Your Perfect Tutor | Tutor House')
  // });

I18n.setRootDir()

axios.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    'Accept-Language': I18n.lanKey
  }
  return config
})

export default i18n;
