import I18n from 'utils/i18n';
enum REGION {
  UAE = "UAE",
  UK = "UK",
  CN = "CN"
}

// NOTE: CHINESE ONLY WORKS ON CN

const ENV_REGION = process.env.REACT_APP_ENVIRONMENT

export const isCN = ENV_REGION === REGION.CN
export const isUAE = ENV_REGION === REGION.UAE || isCN // TODO: optimize

export const currency = (isUAE ? I18n.isAr ? 'د.إ': 'AED' : '£')
// export const currency = 
//   ENV_REGION === REGION.UAE 
//     ? I18n.isAr ? 'د.إ' : 'AED' 
//     : isCN ? I18n.isAr ? 'د.إ' : 'AED' 
//     : '£'
