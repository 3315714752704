import React, { CSSProperties,useContext,useState,useEffect } from 'react';
import { Col, Row,Dropdown,Menu } from 'antd';
import Layout from 'antd/lib/layout/layout';
import AuthContext from 'context/AuthContext';
import { Link } from 'react-router-dom';
import loginLogo from 'static/img/uaeLogoFooter.png';
import css from './FooterUae.module.scss';
import cn from 'classnames';
import {
  KEY_CONTENT_ROUTES,
} from 'routing';
import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next';
import I18n, { lans } from 'utils/i18n';

const styles: { [key: string]: CSSProperties } = {
  columnTitle: {
    fontWeight: 600,
    color: '#130217',
    margin: '1em 0'
  },
  logo: {
    height: '40px'
  },
  legalSocial: {}
};
const dropDownArrow=(
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 5.5L6 1.5L2 5.5" stroke="#fff" strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

const MenuDropDown = ({
  title,
  onChange,
  children,
  opened
}: {
  title: string;
  onChange: (e: boolean) => void,
  children?: JSX.Element,
  opened: boolean;
}): JSX.Element => (
  <Dropdown
    onVisibleChange={onChange}
    overlay={children}
    trigger={['click']}
    overlayClassName={css.uaedropDownOverlay}
  >
    <a
      className={cn(css.dropDownLabelUae, { [css.dropDownOpened]: opened })}
      onClick={e => e.preventDefault()}
    >
      <span>
        {title}
      </span>
      <div 
      className={css.dropDownArrow}
      style={{ transform: `rotate(${opened ? 0 : 180}deg)` }}>
        {dropDownArrow}
      </div>
    </a>
  </Dropdown>
);


export default function FooterUae() {
  const { t,i18n } = useTranslation();
  const [menuOpened, setMenuOpened] = useState<any>({});
  const auth = useContext(AuthContext);
  const [hasLogin,setHasLogin]=useState<boolean>(false)

useEffect(()=>{
  if(auth.user){
    setHasLogin(true)
  }else{
    setHasLogin(false)
  }
}, [auth.user])

  return (
    <div  className={hasLogin?css.loginFooterWarp:css.unLoginFooterWarp}>
      <div className={css.CopyrightDate}>
        <Row style={styles.columnTitle}>
          <LazyLoad>
            <img src={loginLogo} style={styles.logo} alt="Tutor House Logo" />
          </LazyLoad>
        </Row>
        <Row  className={css.privacyItems}>
          <span>
            {t('Copyright')} {new Date().getFullYear()}. {t('All rights reserved')}
          </span>
        </Row>
      </div>
      {hasLogin?<div  className={css.actionItems}>
        <Row>
          <div className={css.thFooterItems}>
            <Link to={KEY_CONTENT_ROUTES.ABOUT} className={css.thFooterItem}>
              {t('About Us')}
            </Link>
            <Link to={KEY_CONTENT_ROUTES.PRIVATE_TUTORING_PERMIT} className={css.thFooterItem}>
              {t('Private Tutoring Permit')}
            </Link>
            <Link to={KEY_CONTENT_ROUTES.HOW_IT_WORKS} className={css.thFooterItem}>
              {t('How it works')}
            </Link>
          </div>
        </Row>
      {/* </div>: <Row className={cn([css.actionItemWarp], {[css.directionLeft]: !I18n.isAr, [css.directionRight]: I18n.isAr})}> */}
      </div>: <Row className={css.actionItemWarp}>
        <Col md={10}  className={css.actionItem}>
          <Link to={KEY_CONTENT_ROUTES.ABOUT} className={css.thFooterItem}>
            {t('About Us')}
          </Link>
          <Link to={KEY_CONTENT_ROUTES.PRIVATE_TUTORING_PERMIT} className={css.thFooterItem}>
            {t('Private Tutoring Permit')}
          </Link>
          <Link to={KEY_CONTENT_ROUTES.HOW_IT_WORKS} className={css.thFooterItem}>
            {t('How It Works')}
          </Link>
        </Col>
        <Col md={10} className={css.actionItem}>
          <Link to={KEY_CONTENT_ROUTES.SEARCH_RESULTS} className={css.thFooterItem}>
            {t('Find A Tutor')}
          </Link>
          <Link to={KEY_CONTENT_ROUTES.STUDENT_CREATE_ACCOUNT} className={css.thFooterItem}>
            {t('Join To Learn')}
          </Link>
          <Link to={KEY_CONTENT_ROUTES.TUTOR_CREATE_ACCOUNT} className={css.thFooterItem}>
            {t('Join To Teach')}
          </Link>
          <a href='mailto:community@oktopi.ai' className={css.thFooterItem}>
            {t('Contact us')}
          </a>
          <Link to={KEY_CONTENT_ROUTES.TUTOR_OR_CUSTOMER_LOGIN} className={cn(css.thFooterItem,css.loginIn)}>
            {t('Log In')}
          </Link>
        </Col>
        <Col md={4} className={css.actionItem}>
        {<MenuDropDown
              title={I18n.getLanLabelOfKey(i18n.language)}
              onChange={(v) => setMenuOpened({ lan: v })}
              opened={menuOpened.lan}
            >
              <Menu 
                className={css.navSubmenu} 
                onClick={() => setMenuOpened({ lan: false })}
              >
                {lans.map(lan => 
                  <Menu.Item key={lan.key}>
                    <a
                      className={css.navItem}
                      onClick={() => {
                        I18n.changeLanguage(lan.key)
                      }}
                    >
                      {lan.label}
                    </a>
                  </Menu.Item>
                )}
              </Menu>
            </MenuDropDown>}
        
        </Col>
      </Row>}
    </div>
  );
}
