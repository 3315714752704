import React, { useCallback, useContext, useReducer, useState } from 'react';
import { Button, Form, Icon, Input, Select, Tooltip, notification } from 'antd';
import { helperTextForPassword, isValidEmail, isValidName, isValidNewPassword, isValidPhone, isValidVerification, sanitizePhoneNumber } from 'utils';
import css from './CreateStudentAccountForm.module.scss';
import { Box } from 'rebass';
import reducer, {reducerInitialState} from './reducer';
import {CREATE_ACTIONS, requestCreateCustomerAccount} from './actions';
import IntlCodes from 'utils/intlCodes.json';
import { Link } from 'react-router-dom';
import { KEY_CONTENT_ROUTES } from 'routing';
import { User } from 'types/Users';
import { History } from 'history';
import cx from 'classnames';
import { AUTH_EXPIRY, AUTH_TOKEN, saveItem, USER_PROFILE } from '../../utils/storage';
import AuthContext from 'context/AuthContext';
import { useTranslation } from 'react-i18next';
import { isUAE } from 'utils/env';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { POST } from 'utils/useApi';
import cn from 'classnames';
import i18n from 'utils/i18n';
import {AxiosResponse} from "axios";
interface CreateStudentAccountFormProps {
  onCreateSuccess: (user: User, token: string) => any,
  onCreateError?: () => void,
  history: History,
  simplified?: boolean | undefined,
  hideFooter?: boolean,
  hideTerms?: boolean,
  submitBtnClassName?: string,
}

export default function CreateStudentAccountForm(props: CreateStudentAccountFormProps) {
  const {
    history,
    onCreateSuccess,
    onCreateError = () => {},
    simplified = false,
    hideFooter = false,
    hideTerms = false,
    submitBtnClassName = ''
  } = props;
  const { TERMS, PRIVACY_POLICY, LOGIN } = KEY_CONTENT_ROUTES;
  const [state, dispatch] = useReducer(reducer, reducerInitialState);
  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [showSmsVerificationCode, setShowSmsVerificationCode] = useState(false);
  const [smsVerificationSid, setSmsVerificationSid] = useState("");

  const [smsSendCountDown, setSmsSendCountDown] = useState(60);
  const [sendSmsTimerStatus, setSendSmsTimerStatus] = useState("Stop");

  const [emailSendCountDown, setEmailSendCountDown] = useState(60);
  const [sendEmailTimerStatus, setSendEmailTimerStatus] = useState("Stop");

  const setName = (value: string) => {
    dispatch({type: CREATE_ACTIONS.SET_USER_PROP, prop: 'name', value});
  };
  const setEmail = (value: string) => {
    dispatch({type: CREATE_ACTIONS.SET_USER_PROP, prop: 'email', value});
  };
  const setVerificationCode = (value: string) => {
    dispatch({type: CREATE_ACTIONS.SET_USER_PROP, prop: 'verificationCode', value});
  };
  const setPhoneNumber = (value: string) => {
    dispatch({type: CREATE_ACTIONS.SET_USER_PROP, prop: 'phone_number', value});
  };
  const setPassword = (value: string) => {
    dispatch({type: CREATE_ACTIONS.SET_USER_PROP, prop: 'password', value});
  };
  const setIntlCode = (value: string) => {
    dispatch({type: CREATE_ACTIONS.SET_USER_PROP, prop: 'intlCode', value});
  };
  const setSmsVerificationCode = (value: string) => {
    dispatch({type: CREATE_ACTIONS.SET_USER_PROP, prop: 'smsVerificationCode', value});
  };

  const {user, loading, emailExistsError, invalidPhoneError} = state;
  const {name, email, verificationCode, phone_number, password, intlCode, smsVerificationCode } = user;

  const formIsValid = () =>
    (simplified || isValidPhone(phone_number)) &&
    isValidEmail(email) &&
    isValidName(name) &&
    isValidNewPassword(password)
   // && isValidVerification(verificationCode)
  ;

  const auth = useContext(AuthContext);

  const onSuccess = (user: User, token: string) => {
    auth.setUser(user);
    auth.setAuthToken(token);
    saveItem(USER_PROFILE, JSON.stringify(user), AUTH_EXPIRY);
    saveItem(AUTH_TOKEN, token, AUTH_EXPIRY);
    onCreateSuccess(user, token);
  };

  const submitForm = () => {
    if (formIsValid()) {
        var referer = window.location.href;
      const [first_name, ...last_name] = name.split(' ');
      const data: {[key: string]: any} = {
        first_name,
        last_name: last_name.join(' '),
        password,
        email,
        agreed_to_terms: true,
        verification_code: verificationCode,
        referer:referer,
        sms_verification_code: smsVerificationCode,
        sms_Verification_Sid:smsVerificationSid
      };
      if (!simplified) {
        data.phone_number = `${intlCode}${phone_number}`;
      }
      requestCreateCustomerAccount(data, dispatch, onSuccess, onCreateError);
    }
  };

  const validName = isValidName(name);
  const showErrorForName = name.length > 2 && !validName;

  const validEmail = isValidEmail(email);
  const showErrorForEmail = email.length > 5 && !validEmail;


  const validVerification = isValidVerification(verificationCode);
  const validSmsVerification = isValidVerification(smsVerificationCode);
  const showErrorForVerification = verificationCode.length > 2 && !validSmsVerification;
  const showErrorForSMSVerification = verificationCode.length > 2 && !validSmsVerification;
  const validPhone = isValidPhone(phone_number);
  const showErrorForPhone = phone_number.length > 7 && !validPhone;

  const { t } = useTranslation()
  const { executeRecaptcha } = useGoogleReCaptcha();

  const sendVerification = useCallback(async () => {
    if (!validEmail) return;
    if(sendEmailTimerStatus ==="Stop"){
          sendEmailCountdown();
    }else {
      return
    }
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('sendEmailCode');
    const data: {[key: string]: any} = {
      email,
      recaptcha_token: token,
    };
    try {
      const result = await POST(
        'v2/users/email_verification_code/',
        data,
        false,
      );
      setShowVerificationCode(true);
      notification.success({
        className:cn(i18n.isAr&&'isarNotification'),
        message: `${t('Success!')}`,
        description: `${t('A verification code has been successfully sent to your email')}`
      });
    } catch(e) {
      notification.error({
        className:cn(i18n.isAr&&'isarNotification'),
        message: `${t('Oops!')}`,
        description: e.response.data.email || e.response.data.recaptcha_token || `${t('Some error happen when send verification code')}`,
      });
    }

  }, [validEmail, executeRecaptcha, email]);
  function countdown() {
    let seconds = 60;
    setSendSmsTimerStatus('doing');
    function updateCountdown() {
      if (seconds <= 0) {
        clearInterval(countdownInterval);
      setSendSmsTimerStatus("Stop");
      }
      seconds--;
      setSmsSendCountDown(seconds)
    }
    updateCountdown();
    const countdownInterval = setInterval(updateCountdown, 1000);
  }
  function sendEmailCountdown() {
    let seconds = 60;
    setSendEmailTimerStatus('doing');
    function updateCountdown() {
      if (seconds <= 0) {
        clearInterval(countdownInterval);
      setSendEmailTimerStatus("Stop");
      }
      seconds--;
      setEmailSendCountDown(seconds)
    }
    updateCountdown();
    const countdownInterval = setInterval(updateCountdown, 1000);
  }
  // @ts-ignore
  const sendSMSVerification =async () => {
    if(sendSmsTimerStatus==="Stop"){
          countdown();
    }else {
      return
    }
    if (!isValidPhone(phone_number)) return;

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const token = await executeRecaptcha('sendSmsCode');

    const data: {[key: string]: any} = {
      phone: `${intlCode}${phone_number}`,
      recaptcha_token: token,
    };
    try {
      const result = (await POST(
        'v2/users/sms_verification_code/',
        data,
        false,
      )) as AxiosResponse;
      setShowSmsVerificationCode(true);
      // @ts-ignore
      setSmsVerificationSid(result.data.sid)
      // @ts-ignore
      if (result.data.status == "ok") {
        notification.success({
          className: cn(i18n.isAr && 'isarNotification'),
          message: `${t('Success!')}`,
          description: `${t('A SMS verification code has been successfully sent to your phone')}`
        });
      } else {
        // @ts-ignore
        const  error_msg=result.data.error_msg
        notification.error({
          className: cn(i18n.isAr && 'isarNotification'),
          message: `${t('Oops!')}`,
          description:  error_msg ||`${t('Some error happen when send verification code')} `,
        });
      }
    } catch(e) {
      notification.error({
        className:cn(i18n.isAr&&'isarNotification'),
        message: `${t('Oops!')}`,
        description: e.response.data.phone || e.response.data.email || e.response.data.recaptcha_token || `${t('Some error happen when send verification code')}`,
      });
    }

  };

  return (
    <Form layout="vertical" className="ant-next ant-form-medium">
      <Form.Item
        hasFeedback={validName}
        validateStatus={showErrorForName ? 'error' : 'success'}
        help={
          showErrorForName ? `${t('Please enter a first and last name')}` : ''
        }
      >
        <Input
          value={name}
          size="large"
          onChange={e => setName(e.target.value)}
          disabled={loading}
          autoComplete="name"
          placeholder={t("First and Last name")}
        />
      </Form.Item>
      <Form.Item
        // hasFeedback={validEmail}
        validateStatus={emailExistsError || showErrorForEmail ? 'error' : 'success'}
        help={
          emailExistsError
            ? `${t('That email address is already in use')}`
            : showErrorForEmail
            ? `${t('Please enter a valid email address')}`
            : ''
        }
      >
        <Input
          value={email}
          size="large"
          onChange={e => setEmail(e.target.value)}
          disabled={loading}
          autoComplete="email"
          placeholder={t("Email")}
          className={cn(i18n.isAr&&'isarInputSuffix', i18n.isAr && (isValidEmail(email) ? 'isarInputValid' : 'isarInput'))}
          // suffix={
          //   <Button onClick={sendVerification}
          //     className={cn('btn-primary')}
          //     disabled={!validEmail|| sendEmailTimerStatus!=="Stop"}
          //   >
          //      {sendEmailTimerStatus==="Stop"?t("Send code"):`Retry in ${emailSendCountDown}s`}
          //   </Button>
          // }
        />
      </Form.Item>
      {/*{showVerificationCode ||validEmail && */}
      {/*  <Form.Item*/}
      {/*  hasFeedback={validVerification}*/}
      {/*  validateStatus={showErrorForVerification ? 'error' : 'success'}*/}
      {/*  help={*/}
      {/*    showErrorForVerification ? `${t('Please enter a valid verification code')}` : ''*/}
      {/*  }*/}
      {/*>*/}
      {/*  <Input*/}
      {/*    value={verificationCode}*/}
      {/*    size="large"*/}
      {/*    onChange={e => setVerificationCode(e.target.value)}*/}
      {/*    disabled={loading}*/}
      {/*    autoComplete="verification-code"*/}
      {/*    placeholder={t("Verification code")}*/}
      {/*  />*/}
      {/*</Form.Item>*/}

      {/*}*/}

      {!simplified && (
        <Form.Item
          hasFeedback={validPhone || invalidPhoneError || showErrorForPhone}
          validateStatus={
            showErrorForPhone || invalidPhoneError
              ? 'error'
              : ''
          }
          help={
            invalidPhoneError
              ? `${t('Please check that you have entered a valid international code and phone number without any spaces')}`
              : showErrorForPhone
              ? `${t('Please enter a valid phone number without any spaces')}`
              : ''
          }
        >
          <Input
            value={phone_number}
            addonBefore={(
              <Select
                className={css.phoneNumberSelector}
                value={intlCode}
                onChange={(option: string) => setIntlCode(option)}
              >
                {IntlCodes.countries.map((country, idx) => (
                  <Select.Option key={idx} value={country.code} className={css.option}>
                    ({country.code}) <span className={css.mobile}>{country.short_name}</span>
                    <span className={css.desktop}>{country.name}</span>
                  </Select.Option>
                ))}
              </Select>
            )}
            size="large"
            onChange={e => setPhoneNumber(sanitizePhoneNumber(e.target.value))}
            disabled={loading}
            autoComplete="phone"
            placeholder={t("Telephone")}

          />
        </Form.Item>
      )}
      {
        validPhone &&
       <Form.Item
        hasFeedback={validSmsVerification}
        validateStatus={showErrorForSMSVerification ? 'error' : 'success'}
        help={
          showErrorForSMSVerification ? `${t('Please enter a valid SMS verification code')}` : ''
        }
      >
        <Input
          value={smsVerificationCode}
          size="large"
          onChange={e => setSmsVerificationCode(e.target.value)}
          disabled={loading}
          autoComplete="sms-verification-code"
          placeholder={t("Enter OTP")}
                     suffix={
              <Button onClick={sendSMSVerification}
                className={cn('btn-primary')}
                disabled={!validPhone || sendSmsTimerStatus!=="Stop"}
              >
                {sendSmsTimerStatus==="Stop"?t("Get OTP"):`Retry in ${smsSendCountDown}s`}
              </Button>
            }
        />
      </Form.Item>
    }
      <Form.Item
        hasFeedback={password.length >= 8}
        validateStatus={
          password.length >= 8 && !isValidNewPassword(password)
            ? 'error'
            : 'success'
        }
        help={password.length > 3 ? `${helperTextForPassword(password)}` : ''}
      >
        <Input
          value={password}
          size="large"
          onChange={e => setPassword(e.target.value)}
          type="password"
          disabled={loading}
          autoComplete="new-password"
          placeholder={t("Password")}
        />
      </Form.Item>
      {!isUAE&&!hideTerms && <Box mb={4}>
        {t('By signing up, you are agreeing to Tutor House’s')}
        &nbsp;
        <Link to={TERMS} target="_blank" className={css.termsLink}>
          {t('Terms and Conditions')}
        </Link>
        &nbsp;
        {t('and')}
        &nbsp;
        <Link to={PRIVACY_POLICY} target="_blank" className={css.termsLink}>
          {t('Privacy Policy')}
        </Link>
        .
      </Box>}
      <Box
        mt={0}
        mb={5}
      >
        <Button
          className={cx({
            'ant-submit-btn': true,
            [css.submitFormBtn]: true,
            'btn-primary': true,
            [submitBtnClassName]: true
          })}
          disabled={!formIsValid()}
          loading={loading}
          onClick={submitForm}
        >
          {t('Create Account')}
        </Button>
      </Box>
      {!hideFooter && (
        <>
          <span className={css.goLoginText}>
            {t('Already have an account?')}&nbsp;
          </span>
          <button
            className={css.goLoginBtn}
            onClick={() => history.push(LOGIN)}
          >
            {t('Log In')}
          </button>
        </>
      )}
    </Form>
  );
}
