import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Booking } from 'types';
import { getItem, AUTH_TOKEN } from 'utils/storage';
import { getUnconfirmedBookings } from 'utils';
import { getCustomerBookings, getTutorBookings, getUnreadCustomerBookingsCount, getUnreadTutorBookingsCount } from 'api/booking';
import AuthContext from 'context/AuthContext';
import phoneIcon from 'static/img/premium/phone-icon.svg';
import facebookIcon from 'static/img/facebook.svg';
import twitterIcon from 'static/img/twitter.svg';
import linkedInIcon from 'static/img/linkedIn.svg';
import * as icons from './menuIcons';
import css from './DashboardFooter.module.scss';
import { useTranslation } from 'react-i18next';

interface IDashboardFooterProps {
  location: any
}

function DashboardFooter({ location }: IDashboardFooterProps) {
  const [unConfirmedBookingsCount, setUnConfirmedBookingsCount] = useState(0);
  const [unreadBookingsCount, setUnreadBookingsCount] = useState(0);
  const auth = useContext(AuthContext);
  const token = getItem(AUTH_TOKEN);
  const isLogin = Boolean(token);
  const { t } = useTranslation()
  const menu = [{
    name: 'home',
    Icon: icons.HomeIcon,
    label: `${t('Home')}`,
    link: '/customer-dashboard',
    count: 0
  },
  {
    name: 'messages',
    Icon: icons.MessagesIcon,
    label: `${t('Messages')}`,
    link: '/messages',
    count: 0
  },
  {
    name: 'bookings',
    Icon: icons.BookingsIcon,
    label: `${t('Bookings')}`,
    link: '/bookings',
    count: unreadBookingsCount
  }];

  const getBookingsStats = async () => {
    let bookings: Booking[] = [];
    if (!auth.user) { return; }

    if (auth.user.is_tutor) {
      const res = await getTutorBookings({ completed: false });
      bookings = res.data;
      const tutorUnreadBookingsCount = await getUnreadTutorBookingsCount();
      setUnreadBookingsCount(tutorUnreadBookingsCount.data.count);
    } else if (auth.user.is_customer){
      const res =  await getCustomerBookings({ completed: false });
      bookings = res.data;
      const customerUnreadBookingsCount = await getUnreadCustomerBookingsCount();
      setUnreadBookingsCount(customerUnreadBookingsCount.data.count);
    }

    const unConfirmedBookings = getUnconfirmedBookings(bookings, auth.user.is_customer);
    setUnConfirmedBookingsCount(unConfirmedBookings.length);
  }

  useEffect(() => {
    let interval: number | undefined;
    if (!token) {
      setUnConfirmedBookingsCount(0);
      setUnreadBookingsCount(0);
    } else {
      getBookingsStats();

      interval = setInterval(() => {
        getBookingsStats(); // check available bookings every 2m
      }, 120000);
    }
    return () => clearInterval(interval);
  }, [token]);

  return (
    <div>
      <footer className={css.footer}>
        <div className={css.copyright}>{t('Copyright 2020. All rights reserved.')}</div>
        <div className={css.social}>
          <div className={css.phone}>
            <img src={phoneIcon} alt=""/>
            <a href="tel:+4402039500320">+4420 3950 0320</a>
          </div>
          <div className={css.socialLinks}>
            <a href="https://www.facebook.com/TutorHouseLondon/">
              <img src={facebookIcon} alt="Facebook" />
            </a>
            <a href="https://www.linkedin.com/company/tutor-house-ltd/about/">
              <img src={linkedInIcon} alt="LinkedIn" />
            </a>
            <a href="https://www.twitter.com/tutorhouse?lang=en">
              <img src={twitterIcon} alt="Twitter" />
            </a>
          </div>
        </div>
      </footer>
      {isLogin && <footer className={css.mobileFooter} >
        {menu.map(el => (
          <div className={cn({
            [css.menuItem]: true,
            [css.active]: location.pathname.startsWith(el.link),
            [css.notifyLabel]: false,
          })}
          key={el.name}
          >
            <Link to={el.link}>
              <div className={css.navBar}>
                <el.Icon fill={location.pathname.startsWith(el.link) ? '#7D3FA8' : '#8F8F8F'}></el.Icon>
                <div className={css.label}>{el.label}</div>
                {el.count > 0 && <span className={css.countBadge}>{el.count}</span>}
              </div>
            </Link>
          </div>
        ))}
      </footer>}
    </div>
  );
}

export default withRouter(DashboardFooter);
