declare global {
  interface Window {
    dataLayer: any[]; // 👈️ turn off type checking
  }
}

export const gtm_onClickBookNow = () => {
  console.log('gtm_onClickBookNow');
  window.dataLayer.push({
    event: 'Student clicked book now',
  })
}

export const gtm_onStudentConfirmsDate = () => {
  console.log('gtm_onStudentConfirmsDate');
  window.dataLayer.push({
    event: 'Student confirms a date and time',
  })
}

export const gtm_onStudentMakesPayment = () => {
  console.log('gtm_onStudentMakesPayment');
  window.dataLayer.push({
    event: 'Student confirms payment',
  })
}

export const gtm_onMoneyHeld = () => {
  console.log('gtm_onMoneyHeld');
  window.dataLayer.push({
    event: 'Money is held',
  })
}

export const gtm_onStudentLandsDashboard = () => {
  console.log('gtm_onStudentLandsDashboard');
  window.dataLayer.push({
    event: 'Student lands on dashboard',
  })
}

export const gtm_onTutorClickOnAvailbility = () => {
  console.log('gtm_onTutorClickOnAvailbility');
  window.dataLayer.push({
    event: 'Tutor clicks on availability',
  })
}

export const gtm_onTutorSavesAvailability = () => {
  console.log('gtm_onTutorSavesAvailability');
  window.dataLayer.push({
    event: 'Tutor saves availability changes',
  })
}

export const gtm_onTutorStartWhiteBoard = () => {
  console.log('gtm_onTutorStartWhiteBoard');
  window.dataLayer.push({
    event: 'Tutor starts whiteboard classroom',
  })
}
