import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import SocialAuthBlock from 'components/SocialAuthBlock/SocialAuthBlock';
import { Box } from 'rebass';
import LoginForm from 'components/LoginForm';
import { LoginRegisterSuccessCb } from 'utils/auth';
import { KEY_CONTENT_ROUTES } from 'routing';
import { useTranslation } from 'react-i18next';
import { isUAE } from 'utils/env';
import { notification } from 'antd';
import I18n from 'utils/i18n';
import cn from 'classnames';
interface Props extends RouteComponentProps {
  onSuccess: LoginRegisterSuccessCb,
  isTutor?: boolean,
}

const { FORGOT_PASSWORD } = KEY_CONTENT_ROUTES;

function SignInBlock(props: Props) {
  const {
    onSuccess,
    history,
    isTutor,
  } = props;
  const { t } = useTranslation()

  const onError = (error: string = t('Something went wrong')): void => {
    notification.error({
      className:cn(I18n.isAr&&'isarNotification'),
      message: t('Error'),
      description: error
    });
  };

  return (
    <>
     {!isUAE&&<>
        <SocialAuthBlock
          onSuccess={onSuccess}
          isTutor={isTutor}
        />
        <Box my={3} className="th-word-divider">
          <span>
            {t('or')}
          </span>
        </Box>
      </>}
      <LoginForm
        switchToForgotPass={() => history.push(FORGOT_PASSWORD)}
        onSuccess={onSuccess}
        // onError={() => {}}
        onError={onError}
      />
    </>
  );
}

export default withRouter(SignInBlock);
