import React from 'react';
import {Modal} from 'antd';
import { Box } from 'rebass';
import css from './CTAAuthoredModal.module.scss';
import { ModalProps } from 'antd/es/modal';
import I18n from 'utils/i18n';
import cn from 'classnames';
export interface CTAAuthoredModalProps extends ModalProps {
  avatarUrl?: string | null,
  children: any,
  footer?: any,
  width?: string,
}

export default function CTAAuthoredModal(props: CTAAuthoredModalProps) {
  const {
    avatarUrl,
    children,
    footer,
    width,
    ...otherProps
  } = props;

  const modalWidth = window.innerWidth > 767 ? (width || '75%') :  'calc(100% - 32px)';
  return (
    <Modal
      {...otherProps}
      title=""
      className={cn('ant-next', 'ant-modal-cta-authored',I18n.isAr&&'isarModal')}
      width={modalWidth}
      footer={null}
      closable={(!!otherProps.onCancel)}
    >
      <Box>
        {avatarUrl && (
          <div className={css.avatarContainer}>
            <div
              className={css.avatarElement}
              style={{backgroundImage: `url(${avatarUrl})`}}
            />
          </div>
        )}
        <div className={css.childrenContainer}>
          {children}
        </div>
        {footer && (
          <div className={css.footerContainer}>
            {footer}
          </div>
        )}
      </Box>
    </Modal>
  );
}
