import React, { ReactNode } from 'react';
import { Box } from 'rebass';
import cn from 'classnames';
import css from './SocialAuthButton.module.scss';
import { useTranslation } from 'react-i18next';

interface SocialAuthButtonProps {
  styleColor: string,
  logoUrl: string,
  displayName: string,
  onClick: () => any,
  disabled: boolean | undefined,
  btnClassName?: string;
  children?: ReactNode

  mt?: string | number | undefined,
  mb?: string | number | undefined,
}

export default function SocialAuthButton(props: SocialAuthButtonProps) {
  const {
    styleColor,
    logoUrl,
    displayName,
    onClick,
    disabled,
    btnClassName,
    children,
    mt,
    mb,
  } = props;
  const { t } = useTranslation()
  const text = `${t('Sign in with')} ${displayName}`;
  return (
    <Box
      mt={mt}
      mb={mb}
      width="100%"
    >
      <div>
      { !!children
      ? <div onClick={disabled ? undefined : onClick}>{children}</div>
      :
        <div
          onClick={disabled ? undefined : onClick}
          className={cn(css.buttonContainer, btnClassName)}
          style={{ borderColor: styleColor, backgroundColor: styleColor}}
        >
          <span className={css.imageContainer}>
            <img
              src={logoUrl}
              alt={logoUrl}
            />
          </span>
          <span className={css.textContainer}>
            {text}
          </span>
        </div>
      }
      </div>
    </Box>
  );
}
