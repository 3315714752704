import React, { CSSProperties } from 'react';
import { Col, Row } from 'antd';
import Layout from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import Logo from 'static/img/newlogo.svg';
import facebookIcon from 'static/img/facebook.svg';
import twitterIcon from 'static/img/twitter.svg';
import linkedInIcon from 'static/img/linkedIn.svg';
import phoneIcon from 'static/img/premium/phone-icon.svg';
import './Footer.scss';
import {
  KEY_CONTENT_ROUTES,
  LINKED_BLOG_POST_ROUTES,
  TOP_A_LEVEL_TUTORS,
  TOP_GCSE_TUTORS
} from 'routing';
import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next';

const { Footer } = Layout;

const styles: { [key: string]: CSSProperties } = {
  columnTitle: {
    fontWeight: 600,
    color: '#130217',
    margin: '1em 0'
  },
  logo: {
    height: '40px'
  },
  legalSocial: {}
};

export default function NomalFooter() {
  const { TERMS, PRIVACY_POLICY, HOMESCHOOLING } = KEY_CONTENT_ROUTES;

  const {
    FIND_A_PRIVATE_TUTOR_BLOG
  } = LINKED_BLOG_POST_ROUTES;

  const {
    TOP_A_LEVEL_ART_TUTORS,
    TOP_A_LEVEL_CHEMISTRY_TUTORS,
    TOP_A_LEVEL_FRENCH_TUTORS,
    TOP_A_LEVEL_LAW_TUTORS,
    TOP_A_LEVEL_MATHS_TUTORS,
    TOP_A_LEVEL_PHYSICS_TUTORS,
    TOP_A_LEVEL_SPANISH_TUTORS
  } = TOP_A_LEVEL_TUTORS;

  const {
    TOP_GCSE_ART_TUTORS,
    TOP_GCSE_CHEMISTRY_TUTORS,
    TOP_GCSE_FRENCH_TUTORS,
    TOP_GCSE_MATHS_TUTORS,
    TOP_GCSE_PHYSICS_TUTORS,
    TOP_GCSE_SPANISH_TUTORS
  } = TOP_GCSE_TUTORS;

  const { t } = useTranslation()

  return (
    <Footer id="th-main-footer">
      <Row type="flex" gutter={16}>
        <Col lg={8} className="th-footer-group">
          <Row style={styles.columnTitle}>
            <LazyLoad>
              <img src={Logo} style={styles.logo} alt="Tutor House Logo" />
            </LazyLoad>
          </Row>
          <Row>
            {t('Improving the quality of education through personalised learning')}
          </Row>
          <Row type="flex" className="privacy-items th-footer-group-desktop">
            <Link to={TERMS} className="th-footer-item">
              {t('Terms')}
            </Link>
            <Link to={PRIVACY_POLICY} className="th-footer-item">
              {t('Privacy Policy')}
            </Link>
            <span>
              {t('Copyright')} {new Date().getFullYear()}. {t('All rights reserved')}
            </span>
          </Row>
        </Col>
        <Col sm={24} className="th-footer-group th-footer-group-mobile">
          <div className="social-cta-links social-cta-links-mobile">
            <Row style={styles.columnTitle}>
              <div className="social-links">
                <a href="https://www.facebook.com/TutorHouseLondon/">
                  <LazyLoad>
                    <img src={facebookIcon} alt="Facebook" />
                  </LazyLoad>
                </a>
                <a href="https://www.linkedin.com/company/tutor-house-ltd/about/">
                  <LazyLoad>
                    <img src={linkedInIcon} alt="LinkedIn" />
                  </LazyLoad>
                </a>
                <a href="https://www.twitter.com/tutorhouse?lang=en">
                  <LazyLoad>
                    <img src={twitterIcon} alt="Twitter" />
                  </LazyLoad>
                </a>
              </div>
            </Row>
            <Row>
              <div className="th-footer-items th-phone-wrapper">
                  <LazyLoad>
                    <img src={phoneIcon} alt="Phone icon" />
                  </LazyLoad>
                <a href="tel:+4402039500320">+4420 3950 0320</a>
              </div>
              <div className="btns">
                <button className="btn-primary">
                  <Link to={KEY_CONTENT_ROUTES.POST_JOB}>
                  {t('Match me with a Tutor')}
                  </Link>
                </button>
                <button className="btn-violet btn-primary">
                  <Link to={KEY_CONTENT_ROUTES.TEACH_WITH_US}>
                    {t('Become a Tutor')}
                  </Link>
                </button>
              </div>
            </Row>
          </div>
        </Col>
        <Col lg={4} sm={24} className="th-footer-group">
          <Row style={styles.columnTitle}>{t('Help &amp; Advice')}</Row>
          <Row>
            <div className="th-footer-items">
              <Link to={KEY_CONTENT_ROUTES.ABOUT} className="th-footer-item">
                {t('About Us')}
              </Link>
              <Link to={KEY_CONTENT_ROUTES.HOW_IT_WORKS} className="th-footer-item">
                {t('How it works')}
              </Link>
              <a href={KEY_CONTENT_ROUTES.SUPPORT/*.FIND_A_TUTOR*/} rel="noopener noreferrer" className="th-footer-item">
                {t('Contact us')}
              </a>
              <Link to={KEY_CONTENT_ROUTES.REVIEWS} rel="noopener noreferrer" className="th-footer-item">
                {t('Reviews')}
              </Link>
              <a
                href="//tutorhouse.co.uk/blog"
                className="th-footer-item"
                rel="noopener noreferrer"
              >
                {t('Tutor House Blog')}
              </a>
              <a
                href={HOMESCHOOLING}
                target="_blank"
                className="th-footer-item"
                rel="noopener noreferrer"
              >
                {t('Homeschooling')}
              </a>
              <a
                href="https://tutorhouse.co.uk/blog/a-level-results-day-2019"
                target="_blank"
                className="th-footer-item"
                rel="noopener noreferrer">
                {t('A-level retakes')}
              </a>
              {/* <Link to={FIND_A_PRIVATE_TUTOR_BLOG} className="th-footer-item">
                Find a private tutor
              </Link> */}
              {/* <a
                href="//tutorhouse.co.uk/blog/become-private-tutor-uk"
                target="_blank"
                className="th-footer-item"
                rel="noopener noreferrer"
              >
                How to become a tutor
              </a> */}
            </div>
          </Row>
        </Col>
        <Col lg={4} sm={24} className="th-footer-group">
          <Row style={styles.columnTitle}>{t('Top A-Level tutors')}</Row>
          <Row>
            <div className="th-footer-items">
              <a
                href={`//tutorhouse.co.uk${TOP_A_LEVEL_MATHS_TUTORS}`}
                className="th-footer-item"
              >
                {t('A-Level Maths Tutors')}
              </a>
              <a
                href={`//tutorhouse.co.uk${TOP_A_LEVEL_CHEMISTRY_TUTORS}`}
                className="th-footer-item">
                {t('A-Level Chemistry Tutors')}
              </a>
              <a
                href={`//tutorhouse.co.uk${TOP_A_LEVEL_FRENCH_TUTORS}`}
                className="th-footer-item"
              >
                {t('A-Level French Tutors')}
              </a>
              <a
                href={`//tutorhouse.co.uk${TOP_A_LEVEL_LAW_TUTORS}`}
                className="th-footer-item"
              >
                {t('A-Level Law Tutors')}
              </a>
              <a
                href={`//tutorhouse.co.uk${TOP_A_LEVEL_PHYSICS_TUTORS}`}
                className="th-footer-item"
              >
                {t('A-Level Physics Tutors')}
              </a>
              <a
                href={`//tutorhouse.co.uk${TOP_A_LEVEL_SPANISH_TUTORS}`}
                className="th-footer-item"
              >
                {t('A-Level Spanish Tutors')}
              </a>
              <a
                href={`//tutorhouse.co.uk${TOP_A_LEVEL_ART_TUTORS}`}
                className="th-footer-item"
              >
                {t('A-Level Art Tutors')}
              </a>
            </div>
          </Row>
        </Col>
        <Col lg={4} sm={24} className="th-footer-group">
          <Row style={styles.columnTitle}>Top GCSE tutors</Row>
          <Row>
            <div className="th-footer-items">
              <a
                href={`//tutorhouse.co.uk${TOP_GCSE_MATHS_TUTORS}`}
                className="th-footer-item"
              >
                {t('GCSE Maths Tutors')}
              </a>
              <a
                href={`//tutorhouse.co.uk${TOP_GCSE_ART_TUTORS}`}
                className="th-footer-item"
              >
                {t('GCSE Art Tutors')}
              </a>
              <a
                href={`//tutorhouse.co.uk${TOP_GCSE_CHEMISTRY_TUTORS}`}
                className="th-footer-item"
              >
                {t('GCSE Chemistry Tutors')}
              </a>
              <a
                href={`//tutorhouse.co.uk${TOP_GCSE_FRENCH_TUTORS}`}
                className="th-footer-item"
              >
                {t('GCSE French Tutors')}
              </a>
              <a
                href={`//tutorhouse.co.uk${TOP_GCSE_PHYSICS_TUTORS}`}
                className="th-footer-item"
              >
                {t('GCSE Physics Tutors')}
              </a>
              <a
                href={`//tutorhouse.co.uk${TOP_GCSE_SPANISH_TUTORS}`}
                className="th-footer-item"
              >
                {t('GCSE Spanish Tutors')}
              </a>
            </div>
          </Row>
        </Col>
        <Col sm={24} className="th-footer-group th-footer-group-mobile">
          <Row type="flex" className="privacy-items">
            <Link to={TERMS} className="th-footer-item">
              {t('Terms')}
            </Link>
            <Link to={PRIVACY_POLICY} className="th-footer-item">
              {t('Privacy Policy')}
            </Link>
            <span>
              {t('Copyright')} {new Date().getFullYear()}. {t('All rights reserved.')}
            </span>
          </Row>
        </Col>

        <Col lg={4} className="th-footer-group th-footer-group-desktop">
          <div className="social-cta-links social-cta-links-mobile">
            <Row style={styles.columnTitle}>
              <div className="social-links">
                <a href="https://www.facebook.com/TutorHouseLondon/">
                  <LazyLoad>
                    <img src={facebookIcon} alt="Facebook" />
                  </LazyLoad>
                </a>
                <a href="https://www.linkedin.com/company/tutor-house-ltd/about/">
                  <LazyLoad>
                    <img src={linkedInIcon} alt="LinkedIn" />
                  </LazyLoad>
                </a>
                <a href="https://www.twitter.com/tutorhouse?lang=en">
                  <LazyLoad>
                    <img src={twitterIcon} alt="Twitter" />
                  </LazyLoad>
                </a>
              </div>
            </Row>
            <Row>
              <div className="th-footer-items th-phone-wrapper">
                <LazyLoad>
                  <img src={phoneIcon} alt="Phone icon" />
                </LazyLoad>
                <a href="tel:+4402039500320">+4420 3950 0320</a>
              </div>
              <div className="btns">
                <button className="btn-primary">
                  <Link to={KEY_CONTENT_ROUTES.POST_JOB}>
                    {t('Match me with a Tutor')}
                  </Link>
                </button>
                <button className="btn-violet btn-primary">
                  <Link to={KEY_CONTENT_ROUTES.TEACH_WITH_US}>
                    {t('Become a Tutor')}
                  </Link>
                </button>
              </div>
            </Row>
          </div>
        </Col>
      </Row>
    </Footer>
  );
}
