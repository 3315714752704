import momentTZ from 'moment-timezone';
import moment from 'moment';
import i18n from './i18n';
import 'moment/locale/en-gb';
import 'moment/locale/ar';
import 'moment-timezone/node_modules/moment/locale/ar'
import { USER_PROFILE, getItem } from './storage';

const lng = i18n.isAr ? 'ar' : 'en-gb';
// moment.locale(lng)
// momentTZ().tz('ar')
// console.log(momentTZ.localeData())
// console.log(momentTZ().localeData())

// eslint-disable-next-line no-underscore-dangle (in case eslint is also used ;) )
// momentTZ.defineLocale(lng, moment.localeData());
moment.locale(lng)
// momentTZ.tz.setDefault('Europe/London')

// @ts-ignore
// momentTZ.defineLocale(lng, moment.localeData())
momentTZ.locale(moment.locale())
// console.log(moment())
// console.log(momentTZ().format('dddd D MMMM'))

const userJson = getItem(USER_PROFILE)
const tz = userJson ? JSON.parse(userJson).user_timezone : momentTZ.tz.guess()
momentTZ.tz.setDefault(tz)
// console.log(momentTZ())

// export default moment;
export default momentTZ;
