import React, { MouseEventHandler } from 'react';
import { User } from 'types/Users';

export interface AuthType {
  authToken: string | null;
  user: User | null;
  setUser: Function;
  setAuthToken: Function;
  logout: MouseEventHandler<HTMLElement>;
  isSignupModalVisible: boolean;
  setSignupModalVisibility: (visibility: boolean) => void;
}

const authDefault: AuthType = {
  authToken: null,
  user: null,
  setUser: () => {},
  setAuthToken: () => {},
  logout: () => {},
  isSignupModalVisible: false,
  setSignupModalVisibility: () => {}
};
const AuthContext = React.createContext(authDefault);

export const AuthProvider = AuthContext.Provider;
export const AuthConsumer = AuthContext.Consumer;
export default AuthContext;

