/**
 * This is a simple storage wrapper, we can swap this with any storage
 * solution we want while the API across the app remains same.
 * Current storage: browser localStorage
 */

// Common storage key names or values used by the app
export const AUTH_TOKEN = 'TH_AUTH_TOKEN';
export const USER_PROFILE = 'TH_USER_PROFILE';
export const AUTH_EXPIRY = 14*24*60*60*1000; // 2 weeks

/**
 * Save a item to storage
 * @param key
 * @param value
 * @param expiry
 */
export function saveItem(key: string, value: string, expiry?: number): void {
  localStorage.setItem(key, value);

  if (expiry) {
    const expiryData = {
      timestamp: Date.now(),
      expiry
    };
    localStorage.setItem(`${key}_EXPIRY`, JSON.stringify(expiryData));
  }
}

/**
 * Retrieve an item from storage if it exists
 * @param key
 */
export function getItem(key: string): string | null {
  let expired = false;
  const expiryStr = localStorage.getItem(`${key}_EXPIRY`);

  if (expiryStr) {
    const expiryData = JSON.parse(expiryStr);
    expired = Date.now() - expiryData.timestamp > expiryData.expiry;
  }

  return expired ? null : localStorage.getItem(key);
}

/**
 * Remove an item from the session storage
 * @param key
 */
export function removeItem(key: string): void {
  localStorage.removeItem(key);
}

export const saveJSON = (field: string, value: any) => {
  window.localStorage.setItem(field, JSON.stringify(value));
};

export const getJSON = (field: string): any =>
  JSON.parse(window.localStorage.getItem(field) || 'null');
