import React, { useContext, ReactNode } from 'react';
import {getTokenByAuthorizationCode, getUserDataByToken} from 'utils/useSocialAuth';

import SocialAuthButton from 'components/SocialAuthButton/SocialAuthButton';
import googleLogo from 'static/img/google-logo.svg';
import linkedInLogo from 'static/img/linkedin-logo.svg';
import facebookLogo from 'static/img/facebook-logo.svg';

import GoogleLogin from 'react-google-login';
import { LinkedIn as LinkedInLogin } from 'react-linkedin-login-oauth2';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { AUTH_EXPIRY, AUTH_TOKEN, saveItem, USER_PROFILE } from 'utils/storage';
import { User } from 'types/Users';
import AuthContext from 'context/AuthContext';
import { LoginRegisterSuccessCb } from 'utils/auth';
import get from 'lodash/get';
import useGoogleAuthCodeLogin from 'utils/useGoogleOAuthService'
import { useTranslation } from 'react-i18next';

interface SocialAuthBlockProps {
  onSuccess?: LoginRegisterSuccessCb,
  onError?: (error: string) => void,
  isTutor?: boolean,
  btnClassName?: string;
  googleBtn?: ReactNode;
  facebookBtn?: ReactNode;
  linkedInBtn?: ReactNode;
}

// const lockedOrDeletedProfile = 'Profile was deleted or locked';

export default function SocialAuthBlock(props : SocialAuthBlockProps) {
  const {
    onSuccess = () => {},
    onError = () => {},
    isTutor = false,
  } = props;
  const auth = useContext(AuthContext);
  const { t } = useTranslation()

  const lockedOrDeletedProfile = t('Profile was deleted or locked');

  const {
    REACT_APP_AUTH_GOOGLE_OAUTH2_PROVIDER,
    REACT_APP_AUTH_GOOGLE_OAUTH2_KEY,
    REACT_APP_AUTH_GOOGLE_OAUTH2_REDIRECT_URI,
    REACT_APP_AUTH_LINKEDIN_OAUTH2_PROVIDER,
    REACT_APP_AUTH_LINKEDIN_OAUTH2_KEY,
    REACT_APP_AUTH_LINKEDIN_OAUTH2_REDIRECT_URI,
    REACT_APP_AUTH_LINKEDIN_OAUTH2_SCOPE,

    REACT_APP_AUTH_FACEBOOK_OAUTH2_PROVIDER,
    REACT_APP_AUTH_FACEBOOK_OAUTH2_KEY,
    REACT_APP_AUTH_FACEBOOK_OAUTH2_REDIRECT_URI,
    REACT_APP_AUTH_FACEBOOK_OAUTH2_SCOPE
  } = process.env;

  const storeCredentials = (user: User, token: string): void => {
    auth.setUser(user);
    auth.setAuthToken(token);
    saveItem(USER_PROFILE, JSON.stringify(user), AUTH_EXPIRY);
    saveItem(AUTH_TOKEN, token, AUTH_EXPIRY);
  };

  const onSuccessfulLogin = (user: User, token: string): void => {
    storeCredentials(user, token);
    onSuccess(user, token, true);
  };

  const onGoogleSuccess = async (res: any) => {
    const {code} = res;
    try {
      const token = await getTokenByAuthorizationCode(
        code,
        REACT_APP_AUTH_GOOGLE_OAUTH2_PROVIDER as string,
        REACT_APP_AUTH_GOOGLE_OAUTH2_REDIRECT_URI as string,
        isTutor
      );
      const user: User = await getUserDataByToken(token);
      onSuccessfulLogin(user, token);
    } catch (e) {
      const data = get(e, 'response.data.error_msg', lockedOrDeletedProfile);
      onError(data);
    }
  };

  const onLinkedInSuccess = async (res: any) => {
    try {
      const {code} = res;
      const token = await getTokenByAuthorizationCode(
        code,
        REACT_APP_AUTH_LINKEDIN_OAUTH2_PROVIDER as string,
        REACT_APP_AUTH_LINKEDIN_OAUTH2_REDIRECT_URI as string,
        isTutor
      );
      const user: User = await getUserDataByToken(token);
      onSuccessfulLogin(user, token);
    } catch (e) {
      const data = get(e, 'response.data.error_msg', lockedOrDeletedProfile);
      onError(data);    }
  };

  const onFacebookSuccess = async (res: any) => {
    try {
      const { accessToken } = res;
      const token = await getTokenByAuthorizationCode(
        null,
        REACT_APP_AUTH_FACEBOOK_OAUTH2_PROVIDER as string,
        REACT_APP_AUTH_FACEBOOK_OAUTH2_REDIRECT_URI as string,
        isTutor,
        accessToken
      );
      const user: User = await getUserDataByToken(token);
      onSuccessfulLogin(user, token);
    } catch (e) {
      const data = get(e, 'response.data.error_msg', lockedOrDeletedProfile);
      onError(data);    }
  };

  const onFailure = () => {

  };

  const googleLogin = useGoogleAuthCodeLogin(onGoogleSuccess, onFailure)


  return (
    <>
      <GoogleLogin
        onSuccess={onGoogleSuccess}
        onFailure={onFailure}
        clientId={REACT_APP_AUTH_GOOGLE_OAUTH2_KEY as string}
        responseType="code"
        redirectUri={REACT_APP_AUTH_GOOGLE_OAUTH2_REDIRECT_URI}
        render={(renderProps) => (
          <SocialAuthButton
            styleColor="#4285F4"
            logoUrl={googleLogo}
            displayName={t("Google")}
            disabled={renderProps.disabled}
            // onClick={renderProps.onClick}
            onClick={() => googleLogin()}
            btnClassName={props.btnClassName}
          >
            {props.googleBtn}
          </SocialAuthButton>
        )}
      />
      <FacebookLogin
        appId={REACT_APP_AUTH_FACEBOOK_OAUTH2_KEY}
        callback={onFacebookSuccess}
        fields="id,name,email,picture"
        scope={REACT_APP_AUTH_FACEBOOK_OAUTH2_SCOPE}
        render={(renderProps: any) => (
          <SocialAuthButton
            mt={4}
            styleColor="#0077B5"
            logoUrl={facebookLogo}
            displayName={t("Facebook")}
            disabled={renderProps.disabled}
            onClick={renderProps.onClick}
            btnClassName={props.btnClassName}
          >
            {props.facebookBtn}
          </SocialAuthButton>
        )}
      />
      <LinkedInLogin
        clientId={REACT_APP_AUTH_LINKEDIN_OAUTH2_KEY as string}
        onSuccess={onLinkedInSuccess}
        onFailure={onFailure}
        redirectUri={REACT_APP_AUTH_LINKEDIN_OAUTH2_REDIRECT_URI as string}
        scope={REACT_APP_AUTH_LINKEDIN_OAUTH2_SCOPE as string}
        renderElement={(renderProps : any) => (
          <SocialAuthButton
            mt={4}
            styleColor="#0077B5"
            logoUrl={linkedInLogo}
            displayName={t("Linkedin")}
            disabled={renderProps.disabled}
            onClick={renderProps.onClick}
            btnClassName={props.btnClassName}
          >
            {props.linkedInBtn}
          </SocialAuthButton>
        )}
      />
    </>
  );
};
