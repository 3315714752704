import { withRouter } from 'react-router-dom';
import React, {useContext } from 'react';
import AuthContext from 'context/AuthContext';
import DashboardFooter from './DashboardFooter'
import HomepageFooter from './HomepageFooter'
import NomalFooter from './NomalFooter'
const NO_FOOTER_PREFIXES = [
    '/post-job',
    '/customer/',
    '/customer-dashboard',
    '/dashboard',
    '/signup',
    '/post',
    '/browse',
    '/messages',
    '/bookings',
    '/a-level-retakes',
    '/profile',
    '/customer-profile',
    '/customer-feedback',
    '/tutor-reference',
    '/booking/',
    '/jobs',
    '/pricing'
  ];
  const DASHBOARD_FOOTER_PREFIXES = [
    '/customer-dashboard',
    '/dashboard',
    '/messages',
    '/bookings',
    '/profile',
    '/customer-profile',
    '/customer-feedback',
    '/tutor-reference',
    '/booking/',
    '/post',
    '/jobs'
  ];
  const showHomepageFooter_PREFIXES=[
    '/',
    '/pricing',
  ]

function FooterUk({ location }: any) {
    const showNomalFooter = !NO_FOOTER_PREFIXES.find(prefix =>
        location.pathname.startsWith(prefix)||location.pathname==='/'
    );
    const showDashboardFooter = !!DASHBOARD_FOOTER_PREFIXES.find(prefix =>
        location.pathname.startsWith(prefix)
    );
    const showHomepageFooter=!!showHomepageFooter_PREFIXES.find(prefix =>
     location.pathname===prefix
    )
    
    
    return(
        <>
        {showNomalFooter && <NomalFooter />}
        {showDashboardFooter && <DashboardFooter />}
        {showHomepageFooter&&<HomepageFooter/>}
        </>
    )
}
export default withRouter(FooterUk);