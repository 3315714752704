import React, { useContext, useState, useRef, useEffect } from 'react';
import { Layout, Menu, Button, Dropdown, Modal } from 'antd';
import cn from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import {
  KEY_CONTENT_ROUTES,
  SUBJECT_PAGE_ROUTES,
  AUTHENTICATED_ROUTES
} from 'routing';
import { POST } from 'utils/useApi';
import AuthContext from 'context/AuthContext';
import NotificationContext from 'context/NotificationContext';
import Logo from 'static/img/newlogo.svg';
import OktopiLogo from 'static/img/oktopi/oktopi-light.svg';
import { logSafelyToConsole, enableWhiteBoardAccess, bookingIsUnconfirmed, getUnconfirmedBookings } from 'utils';
import dropDownArrow from 'static/img/dropDownArrowUk.svg';
import menuBurger from 'static/img/menuBurger.svg';
import { isPrerendering } from 'utils/prerender';
import AnimatedRingIcon from 'components/IconModule/animatedIcons/ring';
import { getCustomerBookings, getTutorBookings, getUnreadCustomerBookingsCount, getUnreadTutorBookingsCount } from 'api/booking';
import { Booking } from 'types';
import moment from 'utils/moment';
import orderBy from 'lodash/orderBy';
import { getItem, AUTH_TOKEN } from 'utils/storage';
import LazyLoad from 'react-lazyload';

import css from './LoginSpecial.module.scss';
import { gtm_onTutorClickOnAvailbility } from 'gtm';
import { useTranslation } from 'react-i18next';
import I18n, { lans } from 'utils/i18n';
import { isUAE } from 'utils/env';
import { saveCountry, saveLevel, saveSubject } from 'utils/useSessionStorage';

const { Header } = Layout;
const { SubMenu } = Menu;

const MenuDropDown = ({
  title,
  onChange,
  children,
  opened,
  count
}: {
  title: string;
  onChange: (e: boolean) => void,
  children?: JSX.Element,
  opened: boolean;
  count?: number;
}): JSX.Element => (
  <Dropdown
    onVisibleChange={onChange}
    overlay={children}
    trigger={['click']}
    overlayClassName={css.dropDownOverlay}
  >
    <a
      className={cn(css.dropDownLabel, { [css.dropDownOpened]: opened })}
      onClick={e => e.preventDefault()}
    >
      <span>
        {title}
        {count && count > 0 ? <span className={css.countBadge}>{count}</span> : null}
      </span>
      <LazyLoad>
        <img
          alt=''
          src={dropDownArrow}
          className={css.dropDownArrow}
          style={{ transform: `rotate(${opened ? 0 : 180}deg)` }}
        />
      </LazyLoad>
    </a>
  </Dropdown>
);

function LoginSpecial({ location }: any) {
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [firstBooking, setFirstBooking] = useState<Booking | null>(null);
  const [unConfirmedBookingsCount, setUnConfirmedBookingsCount] = useState(0);
  const [unreadBookingsCount, setUnreadBookingsCount] = useState(0);
  const [isOktopiDialogVisible, setOktopiModalVisibility] = useState(false);

  const {
    HOME,
    TUTOR_OR_CUSTOMER_LOGIN,
    STUDENT_CREATE_ACCOUNT,
    TUTOR_CREATE_ACCOUNT,
    LOGOUT,
    HOW_IT_WORKS,
    ABOUT,
    JOBS,
    PREMIUM,
    TEACH_WITH_US,
    A_LEVEL_COURSES,
    PRICING,
    HOMESCHOOLING,
    COMMON_ENTRANCE,
    REVIEWS,
    PERSONAL_STATEMENT_SUPPORT,
    CONTACT_US_FORM,
  } = KEY_CONTENT_ROUTES;
  const [menuOpened, setMenuOpened] = useState<any>({});
  const {
    CUSTOMER_DASHBOARD,
    TUTOR_DASHBOARD,
    BOOKINGS,
    MESSAGES,
    SETTINGS,
    AVAILABILITY
  } = AUTHENTICATED_ROUTES;

  const auth = useContext(AuthContext);
  const notifications = useContext(NotificationContext);
  const { t, i18n } = useTranslation()
  // process.env.REACT_APP_OKTOPI_HOME_URL
  const oktopiHomepageUrl='https://oktopi.ai/en/'

  const renderNavItem = (
    route: string,
    title: string,
    count: number = 0,
    onClick?: () => void,
    isSimpleLink?: boolean,
  ): JSX.Element => {
    const [pathname] = route.split("?");

    if (isSimpleLink) {
      return <li className='ant-dropdown-menu-item'>
        <a href={route}
          className={
            cn({
              [css.navItem]: true,
              [css.active]: location.pathname.startsWith(pathname),
            })}
        >{title}</a>
      </li>
    }
    return (
      <Menu.Item key={route} onClick={() => {
        if (onClick) onClick();
        gtm_onTutorClickOnAvailbility();
      }}>
        <Link className={
          cn({
            [css.navItem]: true,
            [css.active]: location.pathname.startsWith(pathname),
          })}
          target={route.startsWith("/") ? "_self" : "_blank"}
          to={route.startsWith("/") ? route : ({ pathname: route })}>
          <span>
            {title}
            {count > 0 && <span className={css.countBadge}>{count}</span>}
          </span>
        </Link>
      </Menu.Item>
    )
  };

  const resetCounters = () => {
    setUnConfirmedBookingsCount(0);
    setUnreadBookingsCount(0);
    setFirstBooking(null);
  }

  const token = getItem(AUTH_TOKEN);
  useEffect(() => {
    let interval: number | undefined;
    if (!token) {
      resetCounters();
    } else {
      getBookingsStats();

      interval = setInterval(() => {
        getBookingsStats(); // check available bookings every 2m
      }, 120000);
    }
    return () => clearInterval(interval);
  }, [token]);

  const resendVerificationEmail = async () => {
    const useToken = true;
    try {
      await POST('v2/users/send_verification_email/', {}, useToken);

      setEmailVerificationSent(true);
    } catch (err) {
      console.error(err);
    }
  };

  const socket = useRef<WebSocket>();

  // Create our socket only once
  useEffect(() => {
    socket.current = notifications.webSocket;
    return () => {
      if (socket.current) {
        socket.current.close();
      }
    };
  }, [notifications.webSocket]);

  // create our socket listeners which we want to re-attach on
  useEffect(() => {
    if (auth.user) {
      if (socket.current) {
        socket.current.addEventListener('message', ev => {
          if (ev.type === 'message') {
            const data = JSON.parse(ev.data);
            if (
              data.unread_message_count &&
              data.unread_message_count !== notifications.unreadMessagesCount
            ) {
              notifications.setUnreadMessagesCount(data.unread_message_count);
            }
          }
        });

        socket.current.onerror = ev => {
          socket.current = notifications.getNewSocket();
          logSafelyToConsole('Socket Error:');
          logSafelyToConsole(ev);
        };
      }
    }
  }, [notifications, auth.user]);


  // useEffect(() => {
  //   notifications.getUnreadNotifications()
  // }, []);

  const getBookingsStats = async () => {
    let bookings: Booking[] = [];
    if (!auth.user) { return; }

    if (auth.user.is_tutor) {
      const res = await getTutorBookings({ completed: false });
      bookings = res.data;
      const tutorUnreadBookingsCount = await getUnreadTutorBookingsCount();
      setUnreadBookingsCount(tutorUnreadBookingsCount.data.count);
    } else if (auth.user.is_customer) {
      const res = await getCustomerBookings({ completed: false });
      bookings = res.data;
      const customerUnreadBookingsCount = await getUnreadCustomerBookingsCount();
      setUnreadBookingsCount(customerUnreadBookingsCount.data.count);
    }

    const unConfirmedBookings = getUnconfirmedBookings(bookings, auth.user.is_customer);
    setUnConfirmedBookingsCount(unConfirmedBookings.length);

    if (bookings.length) {
      bookings = orderBy(bookings, 'start_time', 'asc');
    }
    const booking = bookings.length ? bookings[0] : null;

    setFirstBooking(booking);
  }

  const toggleMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const hasNotifications =
    notifications.unreadMessagesCount + notifications.unreadNotificationsCount >
    0;

  const timeToCallInMins = !firstBooking ? Infinity
    : moment(firstBooking.start_time).diff(moment(), 'minute');

  const openOktopiDialog = () => {
    setOktopiModalVisibility(true);
  }

  const visitOktopi = () => {
    setOktopiModalVisibility(false);
    window.open(oktopiHomepageUrl, "_blank");
  }

  if (isPrerendering()) { return null; }

  return (
    <>
      <Header id={css.header}>
        <div id={css.desktopNav} className={css.navHeader}>
          <div className={css.logoSection}>
            <Link to={
              auth.user && auth.user.is_tutor&&isUAE
                ? TUTOR_DASHBOARD
                : HOME
            } style={{display: 'flex', alignItems: 'center'}}>
              <LazyLoad>
                <img src={Logo} className={css.navLogo} alt="Tutor House Logo" />
              </LazyLoad>
            </Link>
            {/* {!isUAE&&<div onClick={openOktopiDialog}>
              <LazyLoad>
                <img src={OktopiLogo} className={css.oktopiLogo} alt="oktopi Logo" />
              </LazyLoad>
            </div>} */}
            {auth.user && firstBooking && !bookingIsUnconfirmed(firstBooking) && enableWhiteBoardAccess(firstBooking) &&
              <div className={css.lessonBanner}>
                <AnimatedRingIcon />
                <div className={css.timeInfo}>
                  {timeToCallInMins > 0
                    ? <span>{t('Your class starts in')} {timeToCallInMins} {t('min')}</span>
                    : <span>{t('Your class starts NOW!')}</span>
                  }
                  {/* <a onClick={() => goToClassRoom(firstBooking)} target="_blank"> */}
                  <a href='/bookings?type=upcoming'>
                    {t('Join Online Classroom')}
                  </a>
                </div>
              </div>
            }
          </div>
          <Menu className={css.navMenu} mode="horizontal">
            {!isUAE&&(!!(auth.user == null)) &&
              <MenuDropDown
                title={t("Tutoring")}
                onChange={(v) => setMenuOpened({ tutoring: v })}
                opened={menuOpened.tutoring}
              >
                <Menu onClick={() => setMenuOpened({ tutoring: false })}>
                  {/* {renderNavItem(SEARCH_RESULTS, 'Browse all tutors', 0, prefillSearch)} */}
                  {/* {renderNavItem(ONLINE_COURSES, 'Revision Courses')} */}
                  {renderNavItem(COMMON_ENTRANCE, `${t('Common Entrance')}`, 0, undefined, true)}
                  {renderNavItem(PERSONAL_STATEMENT_SUPPORT, `${t("Personal Statement Support")}`, 0, undefined, true)}
                  {renderNavItem(A_LEVEL_COURSES, `${t('A-level Retake Courses')}`, 0, undefined, true)}
                  {renderNavItem(HOMESCHOOLING, `${t('Homeschooling')}`, 0, undefined, true)}
                </Menu>
              </MenuDropDown>
            }
            {isUAE&&(!!(auth.user == null)) &&renderNavItem(KEY_CONTENT_ROUTES.SEARCH_RESULTS, `${t('Find a tutor')}`, 0, () => {
              saveSubject("0");
              saveLevel("0");
              saveCountry("0");
            })}
            {(auth.user && !auth.user.is_tutor)&& (
              renderNavItem(KEY_CONTENT_ROUTES.SEARCH_RESULTS,`${t('Find a tutor')}`, 0, () => {
                saveSubject("0");
                saveLevel("0");
                saveCountry("0");
              }))
            }
            {auth.user && (
              renderNavItem(
                auth.user.is_tutor ? TUTOR_DASHBOARD : CUSTOMER_DASHBOARD,
                `${t('Dashboard')}`
              ))
            }
            {(auth.user && auth.user.is_tutor) && renderNavItem(AVAILABILITY, `${t('Availability')}`)}
            {auth.user &&
              auth.user.is_tutor &&
              renderNavItem(JOBS, `${t('Jobs Board')}`)}
            {auth.user &&
              renderNavItem(
                MESSAGES,
                `${t('Messages')}`,
                notifications.unreadMessagesCount
              )}
            {auth.user &&
              renderNavItem(
                `${BOOKINGS}?type=${unConfirmedBookingsCount && auth.user.is_tutor ? 'trial' : 'upcoming'}`,
                `${t('Bookings')}`,
                unreadBookingsCount,
                () => setUnreadBookingsCount(0)
              )}

            {!isUAE&&auth.user &&
              <Menu.Item>
                <a className={css.navItem}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://497756-tutor-house-help-centre.trengohelp.com/en"
                >
                  {t('Support')}
                </a>
              </Menu.Item>}
            {!isUAE&&!auth.user && (
              <MenuDropDown
                title={t("Subjects")}
                onChange={(v) => setMenuOpened({ subjects: v })}
                opened={menuOpened.subjects}
              >
                <Menu className={css.navSubmenu} onClick={() => setMenuOpened({ subjects: false })}>
                  {SUBJECT_PAGE_ROUTES.map(value => (
                    <Menu.Item key={value.title}>
                      <a className={css.navItem} href={`//tutorhouse.co.uk${value.link}`}>
                        {value.title}
                      </a>
                    </Menu.Item>
                  ))}
                </Menu>
              </MenuDropDown>
            )}
            {!isUAE&&!auth.user && <MenuDropDown
              title={t("About")}
              onChange={(v) => setMenuOpened({ about: v })}
              opened={menuOpened.about}
            >
              <Menu className={css.navSubmenu} onClick={() => setMenuOpened({ about: false })}>
                {renderNavItem(ABOUT, `${t('About Us')}`)}
                {renderNavItem(PRICING, `${t('Pricing')}`)}
                {renderNavItem(REVIEWS, `${t('Reviews')}`)}
              </Menu>
            </MenuDropDown>}
            {/* {isUAE&&!auth.user &&renderNavItem(ABOUT, `${t('About Us')}`)} */}
            {/*{(!auth.user) && renderNavItem(MEMBERSHIP, 'Membership')}*/}
            {/* {(!auth.user) && renderNavItem(TEACH_WITH_US, 'Become a Tutor')} */}
            {!auth.user && renderNavItem(STUDENT_CREATE_ACCOUNT, `${t('Join to learn')}`)}
            {isUAE&&!auth.user && renderNavItem(TUTOR_CREATE_ACCOUNT, `${t('Join to teach')}`)}
            {!auth.user && renderNavItem(TUTOR_OR_CUSTOMER_LOGIN, `${t('Log In')}`)}

            {/* language */}
            {isUAE && <MenuDropDown
              title={I18n.getLanLabelOfKey(i18n.language)}
              onChange={(v) => setMenuOpened({ lan: v })}
              opened={menuOpened.lan}
            >
              <Menu 
                className={css.navSubmenu} 
                onClick={() => setMenuOpened({ lan: false })}
              >
                {lans.map(lan => 
                  <Menu.Item key={lan.key}>
                    <a
                      className={css.navItem}
                      onClick={() => {
                        I18n.changeLanguage(lan.key)
                      }}
                    >
                      {lan.label}
                    </a>
                  </Menu.Item>
                )}
              </Menu>
            </MenuDropDown>}

            {!isUAE&&!auth.user && (
              <Menu.Item>
                <a href={CONTACT_US_FORM}>
                  <Button
                    className={cn('btn-primary', css.btn)}
                  >{t('Request a Tutor')}
                  </Button>
                </a>
              </Menu.Item>
            )}
            {
              !isUAE&&!auth.user && <Menu.Item className={css.contactUsItem}>
                <span>{t('Need help?')}</span>
                <a className={css.contactUs}
                  href="tel:+4402039500320">
                  +4420 3950 0320
                </a>
              </Menu.Item>
            }
          </Menu>
          {
              auth.user &&
              <div className={cn(css.rightDropAuthMenu,css.userName)}>
                <MenuDropDown
                  title={`${t('Welcome')} ${auth.user.first_name} ${auth.user.last_name}`}
                  onChange={(v) => setMenuOpened({ rightBar: v })}
                  opened={menuOpened.rightBar}
                >
                  <Menu className={css.navSubmenu} onClick={() => setMenuOpened({ rightBar: false })}>
                    {renderNavItem(SETTINGS, `${t('Settings')}`)}
                    {!isUAE&&<Menu.Item>
                      <a className={css.navItem}
                        target="_blank"
                        rel="noopener noreferrer"
                        // href="https://help.tutorhouse.co.uk/knowledge"
                        href="https://497756-tutor-house-help-centre.trengohelp.com/enlwjk"
                      >
                        {t('Help Centre')}
                      </a>
                    </Menu.Item>}
                    {renderNavItem(LOGOUT, `${t('Log Out')}`, 0, resetCounters)}
                  </Menu>
                </MenuDropDown>

              </div>
            }
          
        </div>

        <div id={css.mobileNav} className={css.navHeader}>
          <div className={css.mobileLogo}>
            <Link to={HOME}>
              <LazyLoad>
                <img src={Logo} className={css.navLogo} alt="Tutor House Logo" />
              </LazyLoad>
            </Link>
            {/* {!isUAE&&<div onClick={openOktopiDialog}>
              <LazyLoad>
                <img src={OktopiLogo} className={css.oktopiLogo} alt="oktopi Logo" />
              </LazyLoad>
            </div>} */}
          </div>
          <div className={css.navMenu} onClick={toggleMenu}>
            <LazyLoad>
              <img src={menuBurger} alt='icon' className={css.mobileIcon} />
            </LazyLoad>
          </div>
          {showMobileMenu && (
            <Menu
              mode="inline"
              className={css.mobileMenu}
              onSelect={toggleMenu}
            >
              {!isUAE&&!!(auth.user == null) &&
                <SubMenu title={t("Tutoring")} className={css.navSubmenu}>
                  {renderNavItem(COMMON_ENTRANCE, `${t('Common Entrance')}`)}
                  {renderNavItem(PERSONAL_STATEMENT_SUPPORT, `${t("UCAS & University")}`)}
                  {renderNavItem(A_LEVEL_COURSES, `${t('A-level Retake Courses')}`)}
                  {renderNavItem(HOMESCHOOLING, `${t('Homeschooling')}`)}

                </SubMenu>
              }
              {isUAE&&(!!(auth.user == null)) &&renderNavItem(KEY_CONTENT_ROUTES.SEARCH_RESULTS, `${t('Find a tutor')}`, 0, () => {
                saveSubject("0");
                saveLevel("0");
                saveCountry("0");
              })}
              {!auth.user && (
                <SubMenu className={css.navSubmenu} title={t("Subjects")}>
                  {SUBJECT_PAGE_ROUTES.map(value => (
                    <Menu.Item key={`Subjects:{value.title}`}>
                      <a className={css.navItem} href={value.link}>
                        {value.title}
                      </a>
                    </Menu.Item>
                  ))}
                </SubMenu>
              )}
              {!isUAE&&!auth.user && <SubMenu title={t("About")} className={css.navSubmenu}>
                {renderNavItem(ABOUT, `${t('About Us')}`)}
                {/* {renderNavItem(HOW_IT_WORKS, `${t('How it works')}`)} */}
                {renderNavItem(PRICING, `${t('Pricing')}`)}
                {renderNavItem(REVIEWS, `${t('Reviews')}`)}
                {/* {renderNavItem(PREMIUM, `${t('Speak to Us')}`)} */}
              </SubMenu>}
              {/* {isUAE&&!auth.user &&renderNavItem(ABOUT, `${t('About Us')}`)} */}
              {/* {!auth.user && renderNavItem(TEACH_WITH_US, 'Become a Tutor')} */}
              {!auth.user && renderNavItem(STUDENT_CREATE_ACCOUNT, `${t('Join to learn')}`)}
              {isUAE&&!auth.user && renderNavItem(TUTOR_CREATE_ACCOUNT, `${t('Join to teach')}`)}
              {(auth.user && !auth.user.is_tutor)&& (
              renderNavItem(KEY_CONTENT_ROUTES.SEARCH_RESULTS,`${t('Find a tutor')}`, 0, () => {
                  saveSubject("0");
                  saveLevel("0");
                  saveCountry("0");
                }))
              }
              {auth.user && (
                renderNavItem(
                  auth.user.is_tutor ? TUTOR_DASHBOARD : CUSTOMER_DASHBOARD,
                  `${t('Dashboard')}`
                ))
              }
              {(auth.user && auth.user.is_tutor) && renderNavItem(AVAILABILITY, `${t('Availability')}`)}
              {auth.user &&
                auth.user.is_tutor &&
                renderNavItem(JOBS, `${t('Jobs Board')}`)}
              {auth.user &&
                renderNavItem(
                  MESSAGES,
                  `${t('Messages')}`,
                  notifications.unreadMessagesCount
                )}
              {auth.user &&
                renderNavItem(
                  BOOKINGS,
                  `${t('Bookings')}`,
                  unreadBookingsCount,
                  () => setUnreadBookingsCount(0)
                )}
              {auth.user && renderNavItem(SETTINGS, `${t('Settings')}`)}
              {!isUAE&&auth.user &&
                <Menu.Item>
                  <a className={css.navItem}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://help.tutorhouse.co.uk/knowledge"
                  >
                    {t('Support')}
                  </a>
                </Menu.Item>}
              {auth.user && renderNavItem(LOGOUT, `${t('Log Out')}`)}
              {!auth.user && renderNavItem(TUTOR_OR_CUSTOMER_LOGIN, `${t('Log In')}`)}

              {/* language */}
              {isUAE && <SubMenu className={css.navSubmenu} title={I18n.getLanLabelOfKey(i18n.language)}>
                {lans.map(lan => (
                  <Menu.Item key={lan.key}>
                    <a className={css.navItem} onClick={() => {
                      I18n.changeLanguage(lan.key)
                    }}>
                      {lan.label}
                    </a>
                  </Menu.Item>
                ))}
              </SubMenu>}

              {!isUAE&&!auth.user && (
                <Menu.Item>
                  <a href={CONTACT_US_FORM}>
                    <Button
                      className={cn('btn-primary', css.btn)}
                    >{t('Request a Tutor')}
                    </Button>
                  </a>
                </Menu.Item>
              )}
              {
                !isUAE&&!auth.user && <Menu.Item className={css.contactUsItem}>
                  <span>{t('Need help?')}</span>
                  <a className={css.contactUs}
                    href="tel:+4402039500320">
                    +4420 3950 0320
                  </a>
                </Menu.Item>
              }
            </Menu>
          )}
          {hasNotifications && <span className={css.redDot} />}
        </div>
      </Header>
      {auth.user && firstBooking && !bookingIsUnconfirmed(firstBooking) && enableWhiteBoardAccess(firstBooking) &&
        <div className={cn(css.mobile, css.lessonBanner)}>
          <AnimatedRingIcon />
          <div className={css.timeInfo}>
            {timeToCallInMins > 0
              ? <span>{t('Your class starts in')} {timeToCallInMins} {t('min')}</span>
              : <span>{t('Your class starts NOW!')}</span>
            }
            {/* <a onClick={() => goToClassRoom(firstBooking)}> */}
            <a href='/bookings?type=upcoming'>
              {t('Join Online Classroom')}
            </a>
          </div>
        </div>}
      <Modal
        className={cn('ant-next', 'ant-modal-cta-authored',I18n.isAr&&'isarModal')}
        visible={isOktopiDialogVisible}
        footer={null}
        onCancel={() => { }}
        closable={false}
      >
        <section className={css.modalBody}>
          <div className={css.title}>
            <LazyLoad>
              <img src={OktopiLogo} className={css.navLogo} alt="oktopi Logo" />
            </LazyLoad>
            <div className={css.dialogContent}>
              <p>{t('Tutor House has been acquired by oktopi, the Workspace for Education, and will continue to offer first-rate tutoring services here at')}{' '}
              <a href="https://tutorhouse.co.uk/" target="_blank">tutorhouse.co.uk</a>.{' '}
              {t('Find out more at')}{' '}
              <a href={oktopiHomepageUrl} target="_blank">oktopi.ai</a></p>
            </div>
          </div>
          <div className={css.btns}>
            <button className="btn-tertiary" onClick={() => setOktopiModalVisibility(false)}>{t('Cancel')}</button>
            <button className="btn-primary" onClick={visitOktopi}>{t('Visit')} oktopi.ai</button>
          </div>
        </section>
      </Modal>
    </>
  );
}

export default withRouter(LoginSpecial);
