import { LOGIN_ACTIONS, LoginAction } from './actions';
import { logSafelyToConsole } from 'utils';

const { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR } = LOGIN_ACTIONS;

export interface LoginState {
  loading: boolean;
  incorrectEmailOrPasswordError: boolean;
}

export default function reducer(
  state: LoginState,
  action: LoginAction
): LoginState {
  const { type, payload } = action;
  logSafelyToConsole(type);

  switch (type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };

    case LOGIN_SUCCESS:
      if (payload) {
        logSafelyToConsole(payload);
        return {
          ...state,
          loading: false
        };
      } else {
        return {
          ...state,
          loading: false
        };
      }

    case LOGIN_ERROR:
      if (payload) {
        logSafelyToConsole(payload);
        return {
          ...state,
          loading: false,
          incorrectEmailOrPasswordError: true
        };
      } else {
        return {
          ...state,
          loading: false
        };
      }

    default:
      return state;
  }
}
