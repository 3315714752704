import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import css from './EditIntentModal.module.scss';
import NextArrow from './next_arrow.svg'
import Close from './close.svg'
import LeftCircle from './left-circle.svg'
import LeftBg from './left-bg.svg'
import RightCircle from './right-circle.svg'
import WaveBg from './wave-bg.svg'
import MainLady from './main-lady.png'
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import I18n from 'utils/i18n';
import cn from 'classnames';

const EXIT_INTENT_ACTION = 'https://tutorhouse.co.uk/student/register'
const EXIT_INTENT_SHOWN = 'EXIT_INTENT_SHOWN';

function ExitIntentModal() {
  const [showExitIntent, setShowExitIntent] = useState(false)

  const show = (listener: (evt: Event) => void) => {
    document.removeEventListener('mouseout', listener);
    document.removeEventListener('blur', listener);
    localStorage.setItem(EXIT_INTENT_SHOWN, 'true');
    setShowExitIntent(true)
  }

  useEffect(() => {
    const exitListener = (evt: Event) => {
      if (evt instanceof MouseEvent && !(evt as any).toElement && !evt.relatedTarget
          && evt.clientY < 0 || evt instanceof FocusEvent) {
        show(exitListener)
      }
    }
    if(localStorage.getItem(EXIT_INTENT_SHOWN) !== 'true') {
      document.addEventListener('mouseout', exitListener);
      document.addEventListener('blur', exitListener);
      // window.addEventListener('popstate', exitEvent);
    }
    setTimeout(() => {
      if(localStorage.getItem(EXIT_INTENT_SHOWN) !== 'true') {
        show(exitListener)
      }
    }, 10000);
  }, [])

  const { t } = useTranslation()

  return (
    <Modal
      className={cn('ant-next-ei', 'ant-modal-cta-authored', css.verifyPhone,I18n.isAr&&'isarModal')}
      visible={showExitIntent}
      closable={false}
      footer={null}
      width='740px'
    >
      <div dir='ltr' className={css.eiContentWrapper} style={{
        // backgroundImage: `url(${ExitIntentBg})`,
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat'
        // background: 'linear-gradient(252.61deg, #4AA3AA -11.17%, #4E0961 99.58%)'
      }}>
        {/* <img src={LeftCircle} alt="" className={css.leftCircle} />
        <img src={RightCircle} alt="" className={css.rightCircle} /> */}
        {/* <img src={LeftBg} alt="" className={css.leftBg} /> */}
        {/* <div className={css.descForm}> */}
          {/* <div className={css.descTitle}> */}
            {/* Don't miss out! */}
            {/* {t('Exit Easter Revision')} */}
            {/* {t('Achieve a Grade 9 in Maths')} */}
          {/* </div> */}
          {/* <div className={css.descInfo}> */}
            {/* Book your place on our */}
            {/* {t("Make the most of your Easter break with Tutor House's Revision Courses!")} */}
            {/* {t('Sign up for our Year 11 GCSE Maths Fast-Track Course!')} */}
            {/* <span className={css.descInfoCenter}> */}
              {/* &nbsp;Intensive GCSE Maths&nbsp; */}
              {/* &nbsp;{t('GCSE Maths Grade 9')}&nbsp;
            </span>  */}
            {/* Course this February half term. */}
            {/* {t('Fast-Track Course!')} */}
          {/* </div> */}
          {/* <div className={css.descBox} 
            style={{cursor: 'pointer'}}
            onClick={() => {
              setShowExitIntent(false)
              window.open(EXIT_INTENT_ACTION, "_blank")
            }}
          > */}
            {/* <span className={css.descBoxText}> */}
              {/* Maths February Intensive */}
              {/* {t('Easter Revision Courses')} */}
              {/* {t('Learn More')} */}
            {/* </span> */}
            {/* <img src={NextArrow} alt="" /> */}
          {/* </div> */}
        {/* </div> */}
        {/* <img src={MainLady} alt="" className={css.mainLady}/>
        <img src={WaveBg} alt="" className={css.waveBg} /> */}
        <div className={css.contentWarp}>
         {/* <div className={css.nomalText}>{t('Ends November 20')}</div> */}
         <div className={css.upTo}>{t('up to 15%* off')}</div>
         <div className={css.nomalText}>{t('Whatever your goals may be, we want to help you reach them.')}</div>
         <div className={css.nomalText}>{t('Get up to 15% off your sessions booking by signing up NOW')}</div>
         
         <div className={cn(css.ourTeam,css.nomalText)}>{t('Our team will contact you shortly after.')}</div>
         <div className={css.signUpBtnWarp}>
          <div className={css.signUpBtn} 
              onClick={() => {
                setShowExitIntent(false)
                window.open(EXIT_INTENT_ACTION, "_blank")
              }}
            >
                {t('Sign up NOW')}
          </div>
         </div>
         <div className={css.Secure}>{t("Secure your tutoring sessions today and gain automatic entry into our Special Gift Giveaway")}</div>
         <div className={css.Secure}>{t("- it's our way of saying thank you for joining our learning community!")}</div>

         <div className={css.asteriskFineprint}>{t('* Valid with a minimum booking of 10 sessions')}</div>
        </div>
        <img 
          src={Close} alt=""
          className={css.closeBtn} 
          onClick={() => {
            setShowExitIntent(false)
          }}
        />
      </div>
    </Modal>
  )
}

export default withRouter(ExitIntentModal)