import { User } from 'types/Users';
import { IBookingLessonSessionData } from 'types';
import { isUAE } from './env';

const LOGGED_IN_USER_TOKEN = 'LOGGED_IN_USER_TOKEN';
const TUTORING_TYPE = 'TUTORING_TYPE';
const POSTCODE = 'POSTCODE';
const SUBJECT = 'SUBJECT';
const COURSECATEGORY="COURSECATEGORY"
const LEVEL = 'LEVEL';
const COUNTRY = 'COUNTRY';
const CATEGORY='CATEGORY';
export const SUBJECT_LEVEL = 'SUBJECT_LEVEL';
const MIN_PRICE = 'MIN_PRICE';
const MAX_PRICE = 'MAX_PRICE';
const MIN_EXPERIENCE = 'MIN_EXPERIENCE';
const MAX_EXPERIENCE = 'MAX_EXPERIENCE';
const SEARCH_TUTOR_KEYWORD = 'SEARCH_TUTOR_KEYWORD';
const LOGGED_IN_USER_TUTOR_PROFILE = 'LOGGED_IN_USER_TUTOR_PROFILE';
const LEAD_TYPE = 'LEAD_TYPE';
const LESSON_BOOKING_INFO = 'LESSON_BOOKING_INFO';
const STUDENT_INSTANT_BOOK = 'STUDENT_INSTANT_BOOK'
export const TUTOR_ID = 'TUTOR_ID';
export const LOCATION_SLUG = 'LOCATION_SLUG';

export const clearSavedData = (): void => localStorage.clear();

export const clearSavedTutorProfile = () => {
  localStorage.removeItem(LOGGED_IN_USER_TOKEN);
  localStorage.removeItem(LOGGED_IN_USER_TUTOR_PROFILE);
};

export const getSessionVar = (
  name: string,
  defaultValue?: string
): string | null =>
  localStorage.getItem(name)
    ? localStorage.getItem(name)
    : defaultValue
    ? defaultValue
    : null;

export const setSessionVar = (name: string, value: string): void =>
  localStorage.setItem(name, value);

export const getSavedToken = (): string | null =>
  localStorage.getItem(LOGGED_IN_USER_TOKEN);

export const saveToken = (token: string): void =>
  localStorage.setItem(LOGGED_IN_USER_TOKEN, token);

export const getSavedTutoringType = (): string | null =>
  localStorage.getItem(TUTORING_TYPE) || 'ONLINE';
export const saveTutoringType = (type: string): void =>
  localStorage.setItem(TUTORING_TYPE, type);

export const getSavedPostcode = (): string | null =>
  localStorage.getItem(POSTCODE);
export const savePostcode = (postcode: string): void =>
  localStorage.setItem(POSTCODE, postcode);

export const getCourseCategory = (): string | null =>
  localStorage.getItem(COURSECATEGORY);
export const saveCourseCategory = (courseCategory: string): void =>
  localStorage.setItem(COURSECATEGORY, courseCategory);

export const getSavedSubject = (): string | null =>
  localStorage.getItem(SUBJECT);
export const saveSubject = (subject: string): void =>
  localStorage.setItem(SUBJECT, subject);

export const getSavedLevel = (): string | null => localStorage.getItem(LEVEL);
export const saveLevel = (level: string): void =>
  localStorage.setItem(LEVEL, level);

export const getSavedCountry = (): string | null =>
  localStorage.getItem(COUNTRY);
export const saveCountry = (country: string): void =>
  localStorage.setItem(COUNTRY, country);

export const getCategory = (): string | null => localStorage.getItem(CATEGORY);
export const saveCategory = (category: string): void =>
  localStorage.setItem(CATEGORY, category);

export const getSavedSubjectLevel = (): string | null =>
  localStorage.getItem(SUBJECT_LEVEL);
export const saveSubjectLevel = (subjectLevel: string): void =>
  localStorage.setItem(SUBJECT_LEVEL, subjectLevel);

export const getSavedMinPrice = (): string | null =>
  localStorage.getItem(MIN_PRICE) ? localStorage.getItem(MIN_PRICE) : isUAE ? '50' : '20';
export const saveMinPrice = (price: string): void =>
  localStorage.setItem(MIN_PRICE, price);

export const getSavedMaxPrice = (): string | null =>
  localStorage.getItem(MAX_PRICE) ? localStorage.getItem(MAX_PRICE) : isUAE ? '400' : '90';

export const saveMaxPrice = (price: string): void =>
  localStorage.setItem(MAX_PRICE, price);

export const getSavedMinExperience = (): string | null =>
  localStorage.getItem(MIN_EXPERIENCE)
    ? localStorage.getItem(MIN_EXPERIENCE)
    : '0';
export const saveMinExperience = (experience: string): void =>
  localStorage.setItem(MIN_EXPERIENCE, experience);

export const getSavedMaxExperience = (): string | null =>
  localStorage.getItem(MAX_EXPERIENCE)
    ? localStorage.getItem(MAX_EXPERIENCE)
    : '60';
export const saveMaxExperience = (price: string): void =>
  localStorage.setItem(MAX_EXPERIENCE, price);

export const getSavedSearchTutorKeyword = (): string =>
  localStorage.getItem(SEARCH_TUTOR_KEYWORD) || ''
export const saveSearchTutorKeyword = (keyword: string): void =>
  localStorage.setItem(SEARCH_TUTOR_KEYWORD, keyword)

export const getSavedTutorProfile = (): User | undefined => {
  const userDataString = localStorage.getItem(LOGGED_IN_USER_TUTOR_PROFILE);
  return userDataString ? (JSON.parse(userDataString) as User) : undefined;
};

export const saveTutorProfile = (profile: User): void => {
  localStorage.setItem(LOGGED_IN_USER_TUTOR_PROFILE, JSON.stringify(profile));
};

export const saveLeadType = (leadType: string): void => {
  localStorage.setItem(LEAD_TYPE, leadType);
};

export const saveBookingLessonInfo = (data: IBookingLessonSessionData | null) => {
  localStorage.setItem(LESSON_BOOKING_INFO, JSON.stringify(data));
}

export const getBookingLessonInfo = (): IBookingLessonSessionData | undefined => {
  const lesson = localStorage.getItem(LESSON_BOOKING_INFO);
  return lesson ? (JSON.parse(lesson) as IBookingLessonSessionData) : undefined;
}

export const clearBookingLessonInfo = (): void => {
  localStorage.removeItem(LESSON_BOOKING_INFO);
}

export const saveInstantBookingInfo = (data: IBookingLessonSessionData | null): void => {
  localStorage.setItem('STUDENT_INSTANT_BOOK', JSON.stringify(data));
}

export const getInstantBookingInfo = (): IBookingLessonSessionData | undefined => {
  const lesson = localStorage.getItem(STUDENT_INSTANT_BOOK);
  return lesson ? (JSON.parse(lesson) as IBookingLessonSessionData) : undefined;
}

export const clearInstantBookingInfo = (): void => {
  localStorage.removeItem(STUDENT_INSTANT_BOOK);
}