import { withRouter } from 'react-router-dom';
import React, {useContext } from 'react';
import AuthContext from 'context/AuthContext';
import LoginSpecial from './LoginSpecial'
import UnLoginSpecial from './UnLoginSpecial'
import Normal from './Normal'

const VERIFY_MAIL_BANNER_PREFIXES = [
    '/messages',
    '/customer-dashboard',
    '/bookings',
    '/customer/jobs',
  ];
const showSpecial_PREFIXES = [
    '/',
    '/pricing',
  ];
const SHOW_FIND_TUTORS_PREFIXES=[
    '/',
  ];
function HeaderNavUk({ location }: any) {
    const auth = useContext(AuthContext);
    const showBanner = !!VERIFY_MAIL_BANNER_PREFIXES.find(prefix =>
        location.pathname.startsWith(prefix)
      );
    const showSpecial=!!showSpecial_PREFIXES.find(prefix =>
      location.pathname===prefix
    )
    const showFindTutors = !!SHOW_FIND_TUTORS_PREFIXES.find(prefix => location.pathname===prefix)
    return(
        <>
        {!showSpecial&&<Normal showBanner={showBanner} />}
        {showSpecial&&auth.authToken&&<LoginSpecial showBanner={showBanner}></LoginSpecial>}
        {showSpecial&&!auth.authToken&&<UnLoginSpecial showBanner={showBanner} showFindTutors={showFindTutors}></UnLoginSpecial>}
        </>
    )
}
export default withRouter(HeaderNavUk);