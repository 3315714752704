import { getItem, AUTH_TOKEN } from 'utils/storage';

export function getApiEndpoint(url: string): string {
  const endpoint = process.env['REACT_APP_API_ENDPOINT'];
  return `${endpoint}/${url}`;
}


export function getAuthHeader(): Record<string, string> {
  const token = getItem(AUTH_TOKEN);
  return {
    'Authorization': `Token ${token}`
  };
}

export function getQueryFromObj(params: any = {}): string {
  const query = new URLSearchParams(params as any).toString();
  if (query) {
    return `?${query}`;
  }
  return '';
}
