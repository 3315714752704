import React, { useContext, useState } from 'react';
import { Layout, Menu,  Modal } from 'antd';
import cn from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import {
  KEY_CONTENT_ROUTES,
  AUTHENTICATED_ROUTES
} from 'routing';
import AuthContext from 'context/AuthContext';
import Logo from 'static/img/newlogo.svg';
import headerSearch from 'static/img/HeaderSearch.svg'
import OktopiLogo from 'static/img/oktopi/oktopi-light.svg';
import menuBurger from 'static/img/headerFilter.svg';
import { isPrerendering } from 'utils/prerender';
import LazyLoad from 'react-lazyload';

import css from './UnLoginSpecial.module.scss';
import { gtm_onTutorClickOnAvailbility } from 'gtm';
import { useTranslation } from 'react-i18next';
import I18n, { lans } from 'utils/i18n';
import { isUAE } from 'utils/env';
import {
    saveSubject,
    saveLevel
  } from 'utils/useSessionStorage';
import useWindowSize from 'utils/useWindowSize';

const { Header } = Layout;

function UnLoginSpecial({ location, showFindTutors = true }: any) {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isOktopiDialogVisible, setOktopiModalVisibility] = useState(false);

  const {
    HOME,
    SEARCH_RESULTS
  } = KEY_CONTENT_ROUTES;
  const {
    TUTOR_DASHBOARD
  } = AUTHENTICATED_ROUTES;

  const auth = useContext(AuthContext);

  const { SCREEN_WIDTH } = useWindowSize();
  const isMobile = SCREEN_WIDTH < 768;

  const { t, i18n } = useTranslation()
  // process.env.REACT_APP_OKTOPI_HOME_URL
  const oktopiHomepageUrl='https://oktopi.ai/en/'

  const renderNavItem = (
    route: string,
    title: string,
    count: number = 0,
    indexKey: number=0,
    onClick?: () => void,
    isSimpleLink?: boolean,
  ): JSX.Element => {
    const [pathname] = route.split("?");

    if (isSimpleLink) {
      return <li className='ant-dropdown-menu-item'>
        <a href={route}
          className={
            cn({
              [css.navItem]: true,
              [css.active]: location.pathname.startsWith(pathname),
            })}
        >{title}</a>
      </li>
    }
    return (
      <Menu.Item key={indexKey} onClick={() => {
        if (onClick) onClick();
        gtm_onTutorClickOnAvailbility();
      }}>
        <Link className={
          cn({
            [css.navItem]: true,
            [css.active]: location.pathname.startsWith(pathname),
          })}
          target={route.startsWith("/") ? "_self" : "_blank"}
          to={route.startsWith("/") ? route : ({ pathname: route })}>
          <span>
            {title}
            {count > 0 && <span className={css.countBadge}>{count}</span>}
          </span>
        </Link>
      </Menu.Item>
    )
  };

  const toggleMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const visitOktopi = () => {
    setOktopiModalVisibility(false);
    window.open(oktopiHomepageUrl, "_blank");
  }

  const menuData=[
    {
      "title": "Homeschooling",
      "searchKey": "Homeschooling",
      "url":"https://tutorhouse.co.uk/homeschooling"
    },
    {
      "title": "English",
      "searchKey": "English",
      "subejectId":"40",
      "levelId":"0"
    },
    {
      "title": "Maths",
      "searchKey": "Maths",
      "subejectId":"74",
      "levelId":"0"
    },
    {
      "title": "Arts",
      "searchKey": "Arts",
      "subejectId":"29",
      "levelId":"0"
    },
    {
      "title": "Languages",
      "searchKey": "Languages",
      "subejectId":"71",
      "levelId":"0"
    },
    {
      "title": "Coding & Tech",
      "searchKey": "Coding",
      "subejectId":"128",
      "levelId":"0"
      // "url":"https://tutorhouse.co.uk/a/coding"
    },
    {
      "title": "Science",
      "searchKey": "Science",
      "subejectId":"76",
      "levelId":"0"
    }
  ]
  const gotoSearch=(subejectId:string,levelId:string)=>{
    saveSubject(subejectId);
    saveLevel(levelId);
  }
  const findSection=()=>{
    setShowMobileMenu(false)
  }

  if (isPrerendering()) { return null; }

  return (
    <>
      <Header id={css.header}>
        <div id={css.desktopNav} className={css.navHeader}>
          <div className={css.logoSection}>
            <Link to={
              auth.user && auth.user.is_tutor&&isUAE
                ? TUTOR_DASHBOARD
                : HOME
            } style={{display: 'flex', alignItems: 'center'}}>
              <LazyLoad>
                <img src={Logo} className={css.navLogo} alt="Tutor House Logo" />
              </LazyLoad>
            </Link>
          </div>
          <div className={css.meunList}>
            <Menu className={css.navMenu} mode="horizontal">
                    {menuData.map((item,index)=>{
                    return (
                        renderNavItem(item.url?item.url:SEARCH_RESULTS, `${t(item.title)}`,0,index,()=>{gotoSearch(item.subejectId||"",item.levelId||"")})
                    )
                    })}
            </Menu>
          </div>
        </div>

        <div id={css.mobileNav} className={css.navHeader}>
          <div className={css.navMenu} onClick={toggleMenu}>
            <LazyLoad>
              <img src={menuBurger} alt='icon' className={css.mobileIcon} />
            </LazyLoad>
          </div>
          <div className={css.mobileLogo}>
            <Link to={HOME}>
              <LazyLoad>
                <img src={Logo} className={css.navLogo} alt="Tutor House Logo" />
              </LazyLoad>
            </Link>
          </div>
         
          {showMobileMenu && (
            <Menu
              mode="inline"
              className={css.mobileMenu}
              onSelect={toggleMenu}
            >
            {menuData.map((item,index)=>{
              return (
                renderNavItem(item.url?item.url:SEARCH_RESULTS, `${t(item.title)}`,0,index,()=>{gotoSearch(item.subejectId||"",item.levelId||"")})
              )
             })
            }
            </Menu>
          )}
        </div>
        <div>
            <Link to={KEY_CONTENT_ROUTES.STUDENT_CREATE_ACCOUNT} className={css.loginSignUp}>
                {t('Login or Signup')}
            </Link>
        </div>
       
        {!isMobile && showFindTutors && <a onClick={findSection} href='#findSection' className={css.findTutor}>
            {/* <LazyLoad>
                <img
                alt=''
                src={headerSearch}
                />
            </LazyLoad> */}
            <span>
                Find Tutors
            </span>
        </a>}
      </Header>
      <Modal
        className={cn('ant-next', 'ant-modal-cta-authored',I18n.isAr&&'isarModal')}
        visible={isOktopiDialogVisible}
        footer={null}
        onCancel={() => { }}
        closable={false}
      >
        <section className={css.modalBody}>
          <div className={css.title}>
            <LazyLoad>
              <img src={OktopiLogo} className={css.navLogo} alt="oktopi Logo" />
            </LazyLoad>
            <div className={css.dialogContent}>
              <p>{t('Tutor House has been acquired by oktopi, the Workspace for Education, and will continue to offer first-rate tutoring services here at')}{' '}
              <a href="https://tutorhouse.co.uk/" target="_blank">tutorhouse.co.uk</a>.{' '}
              {t('Find out more at')}{' '}
              <a href={oktopiHomepageUrl} target="_blank">oktopi.ai</a></p>
            </div>
          </div>
          <div className={css.btns}>
            <button className="btn-tertiary" onClick={() => setOktopiModalVisibility(false)}>{t('Cancel')}</button>
            <button className="btn-primary" onClick={visitOktopi}>{t('Visit')} oktopi.ai</button>
          </div>
        </section>
      </Modal>
    </>
  );
}

export default withRouter(UnLoginSpecial);
