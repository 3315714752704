import React from 'react';
import { POST } from 'utils/useApi';
import { User } from 'types/Users';
import { AxiosResponse } from 'axios';


export enum LOGIN_ACTIONS {
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_ERROR = 'LOGIN_ERROR'
}

interface LoginData {
  username: string;
  method: string;
  phoneLoginMethod: string;
  sms_verification_code:string;
  password: string;
}

export interface LoginAction {
  type: LOGIN_ACTIONS;
  payload: User | string | null;
}

export type LoginSuccessCb = (user: User, token: string) => void;
export type LoginDispatch = React.Dispatch<LoginAction>;

export async function requestLogin(
  data: LoginData,
  dispatch: LoginDispatch,
  onLogInSuccess: LoginSuccessCb,
  onLogInError: Function
): Promise<void> {
  const { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR } = LOGIN_ACTIONS;

  dispatch({
    type: LOGIN_REQUEST,
    payload: null
  });

  try {
    const useToken = false;
    const result = (await POST(
      'v2/users/login_new/',
      data,
      useToken
    )) as AxiosResponse;

    if (result.data.user && result.data.token) {
      const { user, token } = result.data;

      dispatch({
        type: LOGIN_SUCCESS,
        payload: user
      });

      onLogInSuccess(user, token);
    } else if (result.data.status === 'fail') {
      if (
        'error_msg' in result.data &&
        result.data.error_msg === 'Invalid credentials'
      ) {
        dispatch({
          type: LOGIN_ERROR,
          payload: 'INCORRECT_EMAIL_OR_PASSWORD'
        });
      } else {
        dispatch({
          type: LOGIN_ERROR,
          payload: null
        });
      }

      onLogInError(result.data.error_msg);
    }
  } catch (err) {
    if (err.response && err.response.status === 400) {
      const data = err.response.data;
      if ('email' in data) {
        dispatch({
          type: LOGIN_ERROR,
          payload: null
        });
        onLogInError(data.email);
      } else if ('phone' in data) {
        dispatch({
          type: LOGIN_ERROR,
          payload: null
        });
        onLogInError(data.phone);
      } else if ('sms_verification_code' in data) {
        dispatch({
          type: LOGIN_ERROR,
          payload: null
        });
        onLogInError(data.sms_verification_code);
      } else {
        dispatch({
          type: LOGIN_ERROR,
          payload: 'INCORRECT_EMAIL_OR_PASSWORD'
        });
      }
    } else {
      dispatch({
        type: LOGIN_ERROR,
        payload: null
      });

      onLogInError(err.response.data.error_msg);
    }
  }
}
