import React from 'react';

export const HomeIcon = ({ fill="#8F8F8F" }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.9688 23.249H4.03125C2.84231 23.249 1.875 22.2817 1.875 21.0927V9.74805C1.875 9.33386 2.21077 8.99805 2.625 8.99805C3.03923 8.99805 3.375 9.33386 3.375 9.74805V21.0927C3.375 21.4546 3.66938 21.749 4.03125 21.749H19.9688C20.3306 21.749 20.625 21.4546 20.625 21.0927V9.74805C20.625 9.33386 20.9608 8.99805 21.375 8.99805C21.7892 8.99805 22.125 9.33386 22.125 9.74805V21.0927C22.125 22.2817 21.1577 23.249 19.9688 23.249Z" fill={fill}/>
      <path d="M23.25 12.3722C23.058 12.3722 22.8661 12.299 22.7197 12.1525L13.3921 2.82501C12.6245 2.05739 11.3755 2.05739 10.6079 2.82501L1.28033 12.1526C0.987457 12.4455 0.512566 12.4455 0.219691 12.1526C-0.0732305 11.8597 -0.0732305 11.3848 0.219691 11.0919L9.54725 1.76433C10.8997 0.411891 13.1003 0.411891 14.4527 1.76433L23.7803 11.0919C24.0732 11.3848 24.0732 11.8596 23.7803 12.1525C23.6339 12.299 23.4419 12.3722 23.25 12.3722Z" fill={fill}/>
      <path d="M15 23.248H9C8.58577 23.248 8.25 22.9122 8.25 22.498V15.8418C8.25 14.5495 9.30141 13.498 10.5938 13.498H13.4062C14.6986 13.498 15.75 14.5495 15.75 15.8418V22.498C15.75 22.9122 15.4142 23.248 15 23.248ZM9.75 21.748H14.25V15.8418C14.25 15.3766 13.8715 14.998 13.4062 14.998H10.5938C10.1285 14.998 9.75 15.3766 9.75 15.8418V21.748Z" fill={fill}/>
    </svg>
  );
};

export const MessagesIcon = ({ fill="#8F8F8F" }) => {
  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.75 0H2.25002C1.00928 0 0 1.00928 0 2.25002V15.75C0 16.9907 1.00928 18 2.25002 18H21.75C22.9907 18 24 16.9907 24 15.75V2.25002C24 1.00928 22.9907 0 21.75 0ZM21.75 1.49999C21.8519 1.49999 21.9488 1.52114 22.0374 1.55806L12 10.2576L1.9625 1.55806C2.05113 1.52119 2.14807 1.49999 2.24997 1.49999H21.75ZM21.75 16.5H2.25002C1.8362 16.5 1.49999 16.1638 1.49999 15.75V3.14282L11.5085 11.8169C11.6499 11.9392 11.825 12 12 12C12.175 12 12.3501 11.9392 12.4915 11.8169L22.5 3.14282V15.75C22.5 16.1638 22.1638 16.5 21.75 16.5Z" fill={fill}/>
    </svg>
  );
};

export const BookingsIcon = ({ fill="#8F8F8F" }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.25 2.75H17V1.25001C17 0.835786 16.6642 0.5 16.25 0.5C15.8358 0.5 15.5 0.835786 15.5 1.25001V2.75H6.49998V1.25001C6.49998 0.835786 6.1642 0.5 5.75001 0.5C5.33583 0.5 5 0.835786 5 1.25001V2.75H2.75C1.50736 2.75 0.5 3.75736 0.5 5V19.25C0.5 20.4926 1.50736 21.5 2.75 21.5H19.25C20.4926 21.5 21.5 20.4926 21.5 19.25V5C21.5 3.75736 20.4926 2.75 19.25 2.75ZM20 19.25C20 19.6642 19.6642 20 19.25 20H2.75C2.33577 20 1.99998 19.6642 1.99998 19.25V9.49999H20V19.25ZM20 8.00001H1.99998V5C1.99998 4.58577 2.33577 4.24998 2.75 4.24998H5V5.74997C5 6.1642 5.33578 6.49998 5.75001 6.49998C6.16424 6.49998 6.50003 6.1642 6.50003 5.74997V4.24998H15.5V5.74997C15.5 6.1642 15.8358 6.49998 16.25 6.49998C16.6643 6.49998 17 6.1642 17 5.74997V4.24998H19.25C19.6643 4.24998 20.0001 4.58577 20.0001 5V8.00001H20Z" fill={fill}/>
      <path d="M6.49999 11H5.00001C4.58579 11 4.25 11.3358 4.25 11.75C4.25 12.1642 4.58579 12.5 5.00001 12.5H6.49999C6.91422 12.5 7.25001 12.1642 7.25001 11.75C7.25001 11.3358 6.91422 11 6.49999 11Z" fill={fill}/>
      <path d="M11.75 11H10.25C9.83579 11 9.5 11.3358 9.5 11.75C9.5 12.1642 9.83579 12.5 10.25 12.5H11.75C12.1642 12.5 12.5 12.1642 12.5 11.75C12.5 11.3358 12.1642 11 11.75 11Z" fill={fill}/>
      <path d="M17 11H15.5C15.0858 11 14.75 11.3358 14.75 11.75C14.75 12.1642 15.0858 12.5 15.5 12.5H17C17.4142 12.5 17.75 12.1642 17.75 11.75C17.75 11.3358 17.4142 11 17 11Z" fill={fill}/>
      <path d="M6.49999 14H5.00001C4.58579 14 4.25 14.3358 4.25 14.75C4.25 15.1642 4.58579 15.5 5.00001 15.5H6.49999C6.91422 15.5 7.25001 15.1642 7.25001 14.75C7.25001 14.3358 6.91422 14 6.49999 14Z" fill={fill}/>
      <path d="M11.75 14H10.25C9.83579 14 9.5 14.3358 9.5 14.75C9.5 15.1642 9.83579 15.5 10.25 15.5H11.75C12.1642 15.5 12.5 15.1642 12.5 14.75C12.5 14.3358 12.1642 14 11.75 14Z" fill={fill}/>
      <path d="M17 14H15.5C15.0858 14 14.75 14.3358 14.75 14.75C14.75 15.1642 15.0858 15.5 15.5 15.5H17C17.4142 15.5 17.75 15.1642 17.75 14.75C17.75 14.3358 17.4142 14 17 14Z" fill={fill}/>
      <path d="M6.49999 17H5.00001C4.58579 17 4.25 17.3358 4.25 17.75C4.25 18.1642 4.58579 18.5 5.00001 18.5H6.49999C6.91422 18.5 7.25001 18.1642 7.25001 17.75C7.25001 17.3357 6.91422 17 6.49999 17Z" fill={fill}/>
      <path d="M11.75 17H10.25C9.83579 17 9.5 17.3358 9.5 17.75C9.5 18.1642 9.83579 18.5 10.25 18.5H11.75C12.1642 18.5 12.5 18.1642 12.5 17.75C12.5 17.3358 12.1642 17 11.75 17Z" fill={fill}/>
      <path d="M17 17H15.5C15.0858 17 14.75 17.3358 14.75 17.75C14.75 18.1642 15.0858 18.5 15.5 18.5H17C17.4142 18.5 17.75 18.1642 17.75 17.75C17.75 17.3358 17.4142 17 17 17Z" fill={fill}/>
    </svg>

  );
};

export const ProfileIcon = ({ fill="#8F8F8F" }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.4246 14.0754C17.281 12.9318 15.9198 12.0852 14.4346 11.5727C16.0253 10.4771 17.0703 8.64357 17.0703 6.57031C17.0703 3.22315 14.3472 0.5 11 0.5C7.65284 0.5 4.92969 3.22315 4.92969 6.57031C4.92969 8.64357 5.97472 10.4771 7.56543 11.5727C6.08026 12.0852 4.71903 12.9318 3.57539 14.0754C1.59221 16.0586 0.5 18.6954 0.5 21.5H2.14062C2.14062 16.6149 6.11492 12.6406 11 12.6406C15.8851 12.6406 19.8594 16.6149 19.8594 21.5H21.5C21.5 18.6954 20.4078 16.0586 18.4246 14.0754ZM11 11C8.55748 11 6.57031 9.01287 6.57031 6.57031C6.57031 4.12775 8.55748 2.14062 11 2.14062C13.4425 2.14062 15.4297 4.12775 15.4297 6.57031C15.4297 9.01287 13.4425 11 11 11Z" fill={fill}/>
    </svg>
  );
};
