import React from 'react';

export interface NotificationType {
  unreadMessagesCount: number | 0;
  unreadNotificationsCount: number | 0;
  webSocket: WebSocket | undefined;
  getNewSocket: Function;
  setUnreadMessagesCount: Function;
  setUnreadNotificationsCount: Function;
  // getUnreadNotifications: Function;
}

const notificationDefault: NotificationType = {
  unreadMessagesCount: 0,
  unreadNotificationsCount: 0,
  webSocket: undefined,
  setUnreadMessagesCount: () => {},
  setUnreadNotificationsCount: () => {},
  // getUnreadNotifications: () => {},
  getNewSocket: () => {},
};
const NotificationContext = React.createContext(notificationDefault);

export const NotificationProvider = NotificationContext.Provider;
export const NotificationConsumer = NotificationContext.Consumer;
export default NotificationContext;

