import { AUTH_EXPIRY, AUTH_TOKEN, USER_PROFILE, getItem, saveItem } from "utils/storage"
import axios from 'axios'
import { notification } from "antd"
import i18n from 'i18next';
import I18n from 'utils/i18n';
import cn from 'classnames';

export const getTimezone = (cb?: (tz?: string) => void) => {
  // const userJson = getItem(USER_PROFILE)
  // if (userJson) {
  //   cb(JSON.parse(userJson).user_timezone) 
  // } else {
    const { REACT_APP_API_ENDPOINT } = process.env
    axios.get(
      `${REACT_APP_API_ENDPOINT}/v2/users/profile/user_profile/`,
      { headers: {Authorization: `Token ${getItem(AUTH_TOKEN)}` } }
    ).then((response) => {
      const user = response.data.data.user
      saveItem(USER_PROFILE, JSON.stringify(user), AUTH_EXPIRY)
      cb && cb(user.user_timezone)
    }, () => {
      const userJson = getItem(USER_PROFILE)
      cb && cb(userJson ? JSON.parse(userJson).user_timezone : '')
    })
  // }
}

export const saveTimezone = (tz: string) => {
  const { REACT_APP_API_ENDPOINT } = process.env
  axios.put(
    `${REACT_APP_API_ENDPOINT}/v2/users/profile/user_profile/`,
    { user_timezone: tz },
    { headers: {Authorization: `Token ${getItem(AUTH_TOKEN)}` } }
  ).then((response) => {
    const user = response.data.data.user
    saveItem(USER_PROFILE, JSON.stringify(user), AUTH_EXPIRY)
    notification.success({
      className:cn(I18n.isAr&&'isarNotification'),
      message: i18n.t('Success!'),
      description: i18n.t('You have updated your timezone')
    });
    window.location.reload()
  }, (error) => {
    notification.error({
      className:cn(I18n.isAr&&'isarNotification'),
      message: i18n.t('Oops!'),
      description: i18n.t('There was an error while updating your profile. Please try again.'),
      duration: 0
    });
  })
}

export const updateTimezone = () => {
  const token = getItem(AUTH_TOKEN)
  if (!token) return
  const { REACT_APP_API_ENDPOINT } = process.env
  axios.get(
    `${REACT_APP_API_ENDPOINT}/v2/users/profile/user_profile/`,
    { headers: {Authorization: `Token ${token}` } }
  ).then((response) => {
    const userJson = getItem(USER_PROFILE)
    const user = response.data.data.user
    if (userJson) {
      if (user.user_timezone !== JSON.parse(userJson).user_timezone) {
        saveItem(USER_PROFILE, JSON.stringify(user), AUTH_EXPIRY)
        window.location.reload()
      }
    } else {
      saveItem(USER_PROFILE, JSON.stringify(user), AUTH_EXPIRY)
      window.location.reload()
    }
  })
}