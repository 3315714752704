export enum KEY_CONTENT_ROUTES {
  HOME = '/',
  LOADING='/loading',
  BLOG = '/blog',
  TUTORING = '/tutoring',
  ABOUT = '/about-us',
  PRIVATE_TUTORING_PERMIT = '/private-tutoring-permit',
  CONTACT = '/contact',
  GOALS = '/goals',
  LOGIN = '/login/',
  TUTOR_LOGIN = '/tutor/login',
  FORGOT_PASSWORD = '/forgot-password',
  CONFIRM_NEW_PASSWORD = '/password/reset',
  TERMS = '/terms',
  PRIVACY_POLICY = '/privacy-policy',
  SEARCH = '/search',
  SEARCH_RESULTS = '/search-results',
  JOB_POST = '/post-job',
  GET_STARTED = '/getting-started',
  RETAKES = '/retakes',
  HOMESCHOOLING = 'https://tutorhouse.co.uk/homeschooling',
  REVISION_COURSES = '/revision-courses',
  TUTOR_OR_CUSTOMER_LOGIN = '/login',
  TUTOR_CREATE_ACCOUNT = '/tutor/register',
  STUDENT_CREATE_ACCOUNT = '/student/register',
  STUDENT_CREATE_ACCOUNT_NOW = '/student/register-now',
  LOGOUT = '/logout',
  REGISTER_ROLE = '/register-role',
  FIND_TUTOR = '/find-tutor',
  POST_JOB = '/post',
  BROWSE_TUTOR = '/browse',
  CORONA_VIRUS = '/coronavirus',
  INTERNATIONAL_STUDENTS = '/international-students',
  HOW_IT_WORKS = '/how-it-works',
  TEACH_WITH_US = '/teach-with-us',
  OUR_TEAM = '/our-team',
  ONLINE_COURSES = '/online-courses',
  ONLINE_REVISION_COURSES = '/online-courses/gcse-alevel-courses',
  ONLINE_CLASSES_KS2_KS3 = '/online-courses/ks2-ks3-courses',
  PREMIUM = '/premium',
  JOBS = '/jobs',
  ONLINE_COURSES_LANGUAGES = '/online-courses/languages',
  LEARN_ONLINE_LANGUAGE = '/online-courses/languages/:slug',
  PRICING = '/pricing',
  // A_LEVEL_COURSES = '/a-level-retakes',
  A_LEVEL_COURSES = 'https://tutorhouse.co.uk/a-level-retakes',
  CUSTOMER_FEEDBACK = '/customer-feedback',
  CUSTOMER_REFERENCE = '/tutor-reference',
  LEADERBOARD= '/leaderboard',
  CONTACT_US = '/contact-us',
  REVIEWS = '/reviews',
  COMMON_ENTRANCE = 'https://tutorhouse.co.uk/common-entrance',
  PERSONAL_STATEMENT_SUPPORT = 'https://tutorhouse.co.uk/ucas-personal-statement-support',
  LESSON_SCHEDULE_CALENDAR = '/lesson-schedule',
  FIND_A_TUTOR = 'https://tutorhouse.co.uk/find-a-tutor',
  A_LEVEL_ENQUIRY = 'https://tutorhouse.co.uk/a-level-enquiry',
  CONTACT_US_FORM = 'https://tutorhouse.co.uk/form',
  SUPPORT = 'https://tutorhouse.co.uk/support'
}

export const SUBJECT_PAGE_ROUTES = [
  { title: 'Maths tutors', link: '/a/maths' },
  { title: 'Piano tutors', link: '/a/piano' },
  { title: 'Art tutors', link: '/a/art' },
  { title: 'Guitar tutors', link: '/a/guitar' },
  { title: 'Spanish tutors', link: '/a/spanish' },
  { title: 'English tutors', link: '/a/english-language' },
  { title: 'Physics tutors', link: '/a/physics' },
  { title: 'Law tutors', link: '/a/law' },
  { title: 'French tutors', link: '/a/french' },
  { title: 'Chemistry tutors', link: '/a/chemistry' }
];

export enum LINKED_BLOG_POST_ROUTES {
  HOMESCHOOLING_BLOG = '/blog/homeschooling-tutor',
  A_LEVEL_RETAKES_BLOG = '/blog/a-level-results-day-2019',
  FIND_A_PRIVATE_TUTOR_BLOG = '/blog/become-a-private-tutor'
}

export enum TOP_A_LEVEL_TUTORS {
  TOP_A_LEVEL_MATHS_TUTORS = '/a/maths/maths-a-level-as-level',
  TOP_A_LEVEL_CHEMISTRY_TUTORS = '/a/chemistry/chemistry-a-level-as-level',
  TOP_A_LEVEL_FRENCH_TUTORS = '/a/french/french-a-level-as-level',
  TOP_A_LEVEL_LAW_TUTORS = '/a/law/law-a-level-as-level',
  TOP_A_LEVEL_PHYSICS_TUTORS = '/a/physics/physics-a-level-as-level',
  TOP_A_LEVEL_SPANISH_TUTORS = '/a/spanish/spanish-a-level-as-level',
  TOP_A_LEVEL_ART_TUTORS = '/a/art'
}

export enum TOP_GCSE_TUTORS {
  TOP_GCSE_MATHS_TUTORS = '/a/maths?f=level:gcse-igcse',
  TOP_GCSE_ART_TUTORS = '/a/art',
  TOP_GCSE_CHEMISTRY_TUTORS = '/a/chemistry?f=level:gcse-igcse',
  TOP_GCSE_FRENCH_TUTORS = '/a/french?f=level:gcse-igcse',
  TOP_GCSE_PHYSICS_TUTORS = '/a/physics?f=level:gcse-igcse',
  TOP_GCSE_SPANISH_TUTORS = '/a/spanish?f=level:gcse-igcse'
}

export enum AUTHENTICATED_ROUTES {
  TUTOR_DASHBOARD = '/dashboard',
  CUSTOMER_DASHBOARD = '/customer-dashboard',
  MESSAGES = '/messages',
  TUTOR_ACCOUNT = '/account',
  TUTOR_BILLING = '/billing',
  TUTOR_ONBOARDING = '/onboarding',
  TUTOR_STRIPE_AUTHORISATION = '/stripe',
  BOOKINGS = '/bookings',
  CUSTOMER_JOBS = '/customer/jobs',
  PROFILE = '/profile',
  CUSTOMER_PROFILE = '/customer-profile',
  SETTINGS = '/settings',
  GOALS = '/goals',
  PAYMENT_DETAILS = '/profile/payment-details',
  AVAILABILITY = '/profile/availability',

  BOOKING = '/booking',
  BOOKING_SUCCESSFUL = '/booking-successful',
  BOOKING_PACKAGES = '/booking/packages',
  BOOKING_CHECKOUT = '/booking/checkout',
  BOOKING_INSTANT_CHECKOUT = '/booking/instant-checkout'
}
