import i18next from "i18next";
import { PUT } from "./useApi";
import { AUTH_TOKEN, getItem } from "./storage";

enum LAN {
  EN = 'en',
  AR = 'ar',
}

enum LAN_LABEL {
  ENGLISH = 'English',
  // ARABIC = 'عربي',
  ARABIC = 'Arabic',
}

enum DIR {
  LTR = 'ltr',
  RTL = 'rtl',
}

const DEFAULT_DIR = DIR.RTL
const DEFAULT_LAN = LAN.AR
const DEFAULT_LAN_LABEL = LAN_LABEL.ARABIC
const DIR_STORAGE_KEY = 'i18nextDir' // keep i18next community
const LAN_STORAGE_KEY = 'i18nextLng' // keep i18next community

interface ILan {
  key: string,
  label: string,
  dir: string,
}

export const lans: ILan[] = [
  { key: LAN.EN, label: LAN_LABEL.ENGLISH, dir: DIR.LTR },
  { key: LAN.AR, label: LAN_LABEL.ARABIC, dir: DIR.RTL },
]

class I18n {

  public get lanKey(): string {
    return i18next.language || localStorage.getItem(LAN_STORAGE_KEY) || DEFAULT_LAN
  }

  public get isAr(): boolean {
    return this.lanKey === LAN.AR
  }

  public get defLan() : string {
    // return this.isAr ? LAN.AR : LAN.EN
    return this.lanKey
  }
  
  public get localeDir() : string {
    return localStorage.getItem(DIR_STORAGE_KEY) || DEFAULT_DIR
  }

  public setDefLan() {
    if (!localStorage.getItem(LAN_STORAGE_KEY)) {
      localStorage.setItem(LAN_STORAGE_KEY, DEFAULT_LAN)

    }
  }
  
  /**
   * Get related language from specified key
   * @param key the key
   * @returns the lan
   */
  private getLanOfKey(key: string) {
    return lans.find(lan => lan.key === key)
  }
  
  /**
   * Get related language label from specified key
   * @param key the key
   * @returns the label
   */
  getLanLabelOfKey(key: string) {
    const lan = this.getLanOfKey(key)
    return lan ? lan.label : DEFAULT_LAN_LABEL
  }

  /**
   * Get related language direction from specified key
   * @param key the key
   * @returns the dir
   */
  getDirOfKey(key: string) {
    const lan = this.getLanOfKey(key)
    return lan ? lan.dir : DEFAULT_DIR
  }

  /**
   * Change language
   * @param key the key
   */
  async changeLanguage(key: string) {
    if (i18next.language === key) return
    i18next.changeLanguage(key)
    const dir = this.getDirOfKey(key)
    // document.body.dir = dir
    this.setRootDir(dir)
    localStorage.setItem(DIR_STORAGE_KEY, dir)
    if (getItem(AUTH_TOKEN)) {
      await PUT('v2/users/profile/user_profile/', { "language": this.lanKey }, true)
    }
    window.location.reload()
  }

  async changeLanFromUri() {
    const url = new URL(window.location.href)
    const lan = url.searchParams.get('lan')
    if (lan) {
      url.searchParams.delete('lan')
      const paramsStr = url.searchParams.toString()
      const newUrl = `${url.origin}${url.pathname}${paramsStr ? `?${paramsStr}` : ''}`;
      window.history.replaceState({}, '', newUrl);
      const ILan = this.getLanOfKey(lan)
      if (ILan) {
        this.changeLanguage(ILan.key)
      }
    }
  }

  /**
   * Initialize document direction
   * @returns 
   */
  setRootDir(dir = this.localeDir) {
    // this.setLanDir(localStorage.getItem(DIR_STORAGE_KEY) || DEFAULT_DIR)
    // const root = document.getElementById('root')
    // root && root.setAttribute('dir', dir)
    document.body.dir = dir
  }

  setModalDir(dir = this.localeDir){
    const modals = document.getElementsByClassName('ant-modal-body')
    console.log(modals)
    if(modals.length){
      for(let i = 0; i < modals.length; i++){
        modals[i].setAttribute('dir', dir)
      }
    }
  }

  /**
   * Remove main direction
   */
  // removeMainDir() {
  //   const main = document.getElementsByClassName('ant-layout-content')[0]
  //   if (!main) return
  //   if (!main.hasAttribute('dir')) return
  //   main.removeAttribute('dir')
  // }

}

export default new I18n()
