import { POST, GET,PUT, DELETE } from 'utils/useApi';
import { ITrialCallInvitation, Booking, TZoomBooking, UnreadBookingsData } from 'types';
import { getQueryFromObj } from './request';

export const sendTrialBooking = (data: Partial<ITrialCallInvitation>) => {
  return POST('v2/customer/trials-calls/', data, true);
};

export const getTutorBookings = (query?: Record<string, any>) => {
  return GET<Booking[]>(`v2/tutor/bookings/${getQueryFromObj(query)}`, true);
};

export const getCustomerBookings = (query?: Record<string, any>) => {
  return GET<Booking[]>(`v2/customer/bookings/${getQueryFromObj(query)}`, true);
}

export const getUnreadTutorBookingsCount = (query?: Record<string, any>) => {
  return GET<UnreadBookingsData>(`v2/tutor/bookings/unread-count/${getQueryFromObj(query)}`, true);
};

export const getUnreadCustomerBookingsCount = (query?: Record<string, any>) => {
  return GET<UnreadBookingsData>(`v2/customer/bookings/unread-count/${getQueryFromObj(query)}`, true);
}

export async function markReadTutorBooking() {
  return POST(`v2/tutor/bookings/mark_booking_as_read/`, {}, true);
};

export async function markReadCustomerBooking() {
  return POST(`v2/customer/bookings/mark_booking_as_read/`, {}, true);
};

export async function rejectTrialInvitation(
  bookingId: number,
  rejectReason?: string
) {
  return POST(`v2/tutor/bookings/${bookingId}/cancel/`, {reason: rejectReason}, true);
};

export async function rejectTrialInvitationCustomer(
  bookingId: number,
  rejectReason?: string
) {
  return POST(`v2/customer/bookings/${bookingId}/cancel/`, {reason: rejectReason}, true);
};

export async function confirmTrialInvitation(
  bookingId: number
) {
  return POST(`v2/tutor/invitations/${bookingId}/accept_invitation/`, {}, true);
};

export async function getBookingById(
  bookingId: number
) {
  return POST<TZoomBooking>(`v2/zoommeeting/${bookingId}`, {}, true);
};
export const getCustomerSerialCoursesBooklist = (query?: Record<string, any>) => {
  return GET<Booking[]>(`v2/customer/serial_courses/`, true);
};
export const getTutorSerialCoursesBooklist = (query?: Record<string, any>) => {
  return GET<Booking[]>(`v2/tutor/serial_courses/`, true);
};
export async function cancelSerialCourseCustomer(
  id: number,
) {
  return DELETE(`v2/customer/serial_courses/${id}`,null, true);
};

