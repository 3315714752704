import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { User } from '../types/Users';

export const getTokenByAuthorizationCode = async (
  code: string | null,
  provider: string,
  redirectUri: string,
  isTutor: boolean,
  access_token?: string,
): Promise<string> => {
  const {REACT_APP_API_ENDPOINT} = process.env;
  const response = await axios.post(
    `${REACT_APP_API_ENDPOINT}/v3/socials/${provider}`,
    {
      code: code || undefined,
      access_token: access_token || undefined,
      redirect_uri: redirectUri,
      is_tutor: isTutor,
    }
  );
  const {token} = response.data;
  return token;
};

export const getUserDataByToken = async (token : string): Promise<User> => {
  const params: AxiosRequestConfig = {
    headers: {
      Authorization: `Token ${token}`
    }
  };
  const {REACT_APP_API_ENDPOINT} = process.env;
  const response: AxiosResponse = await axios.get(
    `${REACT_APP_API_ENDPOINT}/v2/users/profile/user_profile/`,
    params
  );
  const {data} = response;
  return data.data.user;
};
