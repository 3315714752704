import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import AuthContext from 'context/AuthContext';
import { KEY_CONTENT_ROUTES } from 'routing';

interface PrivateProps extends RouteProps {
  component: React.ComponentType<any>;
  tutor?: Boolean;
  student?: Boolean;
  redirectTo?: string;
}

export default function PrivateRoute({
  component: Component,
  redirectTo,
  ...rest
}: PrivateProps) {
  const { TUTOR_OR_CUSTOMER_LOGIN } = KEY_CONTENT_ROUTES;
  const auth = useContext(AuthContext);
  const redirectPath = redirectTo || TUTOR_OR_CUSTOMER_LOGIN;

  return (
    <Route
      {...rest}
      render={props =>
        auth.user && auth.authToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}
